/** @jsxImportSource @emotion/react */
import React from 'react';
import t from '../../../theme/styles';
import { ProjectArticle } from '../ProjectArticle';
import { ProjectBanner } from '../ProjectBanner';
import { ProjectAside } from '../ProjectAside';
import { ProjectPage } from '../ProjectPage';
import { ProjectSectionContainer, ProjectSection, ProjectSectionHeading } from '../ProjectSection';
import { ThemeProvider } from '../../../contexts/ThemeContext';
import { ImageWithCaption } from '../../../components/imageWithCaption';
import { ImageGallery } from '../../../components/imageGallery';
import { ProjectOverview } from '../ProjectOverview';
import { BrowserRouter as Router, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ProjectSubPage } from '../ProjectSubPage';

export function ProjectPhotoAgent() {
    let { path } = useRouteMatch();

    return (
        <ProjectPage title="Photo Agent">
            <ProjectBanner src="/projects/PhotoAgent/gameplay.jpg" alt="" />
            <ProjectArticle>
                <ProjectSectionContainer>
                    <ProjectOverview>
                        <ProjectSectionHeading label="Overview" />
                        <p>
                            Photo Agent was a free-to-play jigsaw puzzle game for the Facebook platform. The narrative of the game was that you're a new freelance photographer who
                            is making their name with help from your assistant, Miranda, and a host of clients, each with specific needs. With a rich set of high quality photo
                            content, the game played up the goal of traveling the world, meeting fun new people and learning about interesting new things.
                        </p>
                    </ProjectOverview>

                    <ProjectAside title="Photo Agent" company="Amazon Game Studios" date="2012">
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Platform</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Facebook</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Tools</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Flash, Actionscript, Invision, Axure</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Skills</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>UX Design, UI Design, Integration, Prototyping</div>
                        </div>
                    </ProjectAside>
                </ProjectSectionContainer>

                <Router>
                    <Switch>
                        <Route exact path={path} component={Article} />
                        <Route exact path={`${path}gallery`}>
                            <Gallery projectPath={path} />
                        </Route>
                    </Switch>
                </Router>
            </ProjectArticle>
        </ProjectPage>
    );
}

function Article() {
    return (
        <React.Fragment>
            <ThemeProvider tint="extra-light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="My Role" />
                        <p>
                            I lead the UI/UX design and integrated all interfaces in Flash/Actionscript. Outside of the character animations, I did all the UI transitions and
                            gameplay feedback.
                        </p>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
            <ThemeProvider tint="dark">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="Gameplay" />
                        <p>
                            The gameplay was a twist on traditional jigsaw puzzle play, where a piece was automatically attached to your cursor to play and wasn't removed until you
                            found the correct empty spot on the board. Scoring was based on speed and accuracy, with a 4 star grading system layered onto thresholds. The natural
                            pace increase as fewer spots remained on the board made for a fun gameplay arc.
                        </p>
                        <p>
                            I spent a lot of time fine tuning the piece reveal, placement, and result feedback to feel smooth and natural and not distract from what can be a pretty
                            focused gameplay flow state. The intro and finished puzzle animation were also important elements for maintaining a connection with the overall
                            narrative.
                        </p>
                        <ImageGallery>
                            <video controls css={[t.w_full]}>
                                <source src="/projects/PhotoAgent/PhotoAgent_Gameplay.webm" type="video/webm" />
                                <source src="/projects/PhotoAgent/PhotoAgent_Gameplay.mp4" type="video/mp4" />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
            <ThemeProvider tint="light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="Clients" />
                        <p>Description of the game's meta and goal structure</p>
                        <ImageGallery>
                            <video controls css={[t.w_full]}>
                                <source src="/projects/PhotoAgent/PhotoAgent_UnlockingClients.webm" type="video/webm" />
                                <source src="/projects/PhotoAgent/PhotoAgent_UnlockingClients.mp4" type="video/mp4" />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                        </ImageGallery>
                        <ImageGallery columns={2}>
                            <ImageWithCaption src="/projects/PhotoAgent/2-Clients.jpg" alt="Client selection menu" caption="Client selection menu" />
                            <ImageWithCaption src="/projects/PhotoAgent/3-MeetNGreetCropped.jpg" alt="Meeting a new client" caption="Meeting a new client" />
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
        </React.Fragment>
    );
}

function Gallery(props: { projectPath: string }) {
    return (
        <ProjectSubPage projectPath={props.projectPath}>
            <ThemeProvider tint="dark">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="Videos" />
                        <ImageGallery>
                            <video controls css={[t.w_full]}>
                                <source src="/projects/PhotoAgent/PhotoAgent_Gameplay.webm" type="video/webm" />
                                <source src="/projects/PhotoAgent/PhotoAgent_Gameplay.mp4" type="video/mp4" />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                            <video controls css={[t.w_full]}>
                                <source src="/projects/PhotoAgent/PhotoAgent_UnlockingClients.webm" type="video/webm" />
                                <source src="/projects/PhotoAgent/PhotoAgent_UnlockingClients.mp4" type="video/mp4" />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>

            <ThemeProvider tint="light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ImageGallery columns={4}>
                            <ImageWithCaption src="/projects/PhotoAgent/2-Clients.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/3-MeetNGreetCropped.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/4-AllAssignments.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/5-Details.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/6-Summary.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/7-Results.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/9-Complete.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/10-Timeline.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/11-Postcard.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/12-DC_Details.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/12-DC_Results.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/13-Friends.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/14-FriendFull.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/15-MeFull.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/16-OutOfEnergy1.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/17_Magazines.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/MagazineBuyout.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/ClientBuyout.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/ComCenter_inbox.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/ComCenter_inbox_empty.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/ComCenter_News1.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/ComCenter_News2.jpg" alt="" />
                        </ImageGallery>

                        <ImageGallery>
                            <ImageWithCaption src="/projects/PhotoAgent/gameplay.jpg" alt="Gameplay mockup" caption="Gameplay mockup" />
                        </ImageGallery>

                        <ImageGallery>
                            <ImageWithCaption src="/projects/PhotoAgent/PhotoAgent_kindleMockup.jpg" alt="PA Kindle/ipad version" caption="PA Kindle/ipad version" />
                        </ImageGallery>

                        <ImageGallery>
                            <ImageWithCaption src="/projects/PhotoAgent/PA_Lisa_Ad.jpg" alt="Facebook page image" caption="Facebook page image" />
                        </ImageGallery>
                    </ProjectSection>
                    <ProjectSection>
                        <p>Full Canvas redesign</p>
                        <ImageGallery columns={4}>
                            <ImageWithCaption src="/projects/PhotoAgent/fullCanvas_details_assignments.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/fullCanvas_details_vacations.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/fullCanvas_gameplay.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/fullCanvas_map_assignments.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/fullCanvas_map_vacations.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/fullCanvas_scoring.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/fullCanvas_scoring_results.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/fullCanvas_scoring_stars.jpg" alt="" />
                            <ImageWithCaption src="/projects/PhotoAgent/fullCanvas_traveling.jpg" alt="" />
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
        </ProjectSubPage>
    );
}
