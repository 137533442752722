/** @jsxImportSource @emotion/react */
import React from 'react';
import t from '../../theme/styles';
import { ProjectSectionContainer, ProjectSection, ProjectSectionHeading } from '../projects/ProjectSection';
import { ProjectOverview } from '../projects/ProjectOverview';
import { ProjectAside } from '../projects/ProjectAside';
import { ProjectArticle } from '../projects/ProjectArticle';
import { ProjectPage } from '../projects/ProjectPage';
import { ProjectBanner } from '../projects/ProjectBanner';
import { PortfolioReviewFooter } from './PortfolioReviewFooter';
import { ThemeProvider } from '../../contexts/ThemeContext';
import { ImageGallery } from '../../components/imageGallery';
import { ImageWithCaption } from '../../components/imageWithCaption';

export function PortfolioReviewDynamicWall() {
    return (
        <ProjectPage>
            <ProjectBanner src="/projects/DynamicWall/Thumb_Goofall.png" alt="" />
            <ProjectArticle>
                <ProjectSectionContainer>
                    <ProjectOverview>
                        <ProjectSectionHeading label="Overview" />
                        <p>
                            The "Dynamic Wall" is a hardware/software product that combines hidden visual technology with engaging depth-sensing camera technology to produce
                            suprising and delightful experiences. The primary use cases involve lobby or large shared space settings, but we also experimented in musical
                            performance atmospheres. The original wall concept was developed by Pilot for Hilton and displayed in the Hilton Innovation Gallery.
                        </p>
                    </ProjectOverview>

                    <ProjectAside title="Dynamic Wall" company="Pilot Lab" date="2019" titleStyle={[t.type_6]}>
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Tools</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Electron, React, Typescript, Intel RealSense Depth Camera</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Skills</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Programming, UX Design, UI Design, Tool Development, Prototyping</div>
                        </div>
                    </ProjectAside>
                </ProjectSectionContainer>
                <ThemeProvider tint="extra-light">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ImageGallery>
                                <video controls css={[t.w_full]}>
                                    <source src="/projects/DynamicWall/Hilton_LED_wall.mp4" type="video/mp4" />
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                                <span css={[t.type_0]}>Original installation of the dynamic wall at the Hilton Innovation Gallery</span>
                            </ImageGallery>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
                <ThemeProvider tint="light">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="Surface" />

                            <ImageGallery>
                                <video controls css={[t.w_full]} poster="/projects/DynamicWall/Pinkfalls.jpg">
                                    <source src="/projects/DynamicWall/Pinkfalls.webm" type="video/webm" />
                                    <source src="/projects/DynamicWall/Pinkfalls.mp4" type="video/mp4" />
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                            </ImageGallery>
                            <ImageGallery columns={4}>
                                <ImageWithCaption src="/projects/DynamicWall/Thumb_Cascades_Coral.png" alt="" caption="" />
                                <ImageWithCaption src="/projects/DynamicWall/Thumb_Cascades_Teal.png" alt="" caption="" />
                                <ImageWithCaption src="/projects/DynamicWall/Thumb_Goobeam_Teal.png" alt="" caption="" />
                                <ImageWithCaption src="/projects/DynamicWall/Thumb_Goobeam_Merica2.png" alt="" caption="" />
                                <ImageWithCaption src="/projects/DynamicWall/Thumb_Matrix_Man.png" alt="" caption="" />
                                <ImageWithCaption src="/projects/DynamicWall/Thumb_Merica_Firework_Man.png" alt="" caption="" />
                                <ImageWithCaption src="/projects/DynamicWall/Thumb_Orange_Smoke.png" alt="" caption="" />
                                <ImageWithCaption src="/projects/DynamicWall/Thumb_Sea_Anemone.png" alt="" caption="" />
                            </ImageGallery>
                        </ProjectSection>
                    </ProjectSectionContainer>
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="Controller" />

                            <ImageGallery>
                                <ImageWithCaption
                                    src="/projects/DynamicWall/Wall_Controller.JPG"
                                    alt="Dynamic Wall scene sditor software"
                                    caption="Dynamic Wall scene editor software"
                                />
                            </ImageGallery>
                            <ImageGallery>
                                <video controls css={[t.w_full]}>
                                    <source src="/projects/DynamicWall/DynamicWall_Controls.mp4" type="video/mp4" />
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                            </ImageGallery>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
            </ProjectArticle>
            <PortfolioReviewFooter />
        </ProjectPage>
    );
}
