/** @jsxImportSource @emotion/react */
import React from 'react';
import t from '../../theme/styles';
import { Helmet } from 'react-helmet-async';
import { Footer } from '../../components/footer';
import { HomepageProjects } from './HomepageProjects';
import { HomepageSkills } from './HomepageSkills';
import { HomepageTimeline } from './HomepageTimeline';
import { Page } from '../../components/page';
import { LogoIcon } from '../../icons/logoIcon';
import { HomepageQuotes } from './HomepageQuotes';
import { motion, Variants } from 'framer-motion';

const containerVariants: Variants = {
    default: {},
    reveal: {},
};

const headingVariants: Variants = {
    default: { opacity: 0 },
    reveal: { opacity: 1, transition: { ease: 'easeInOut', duration: 1.8, delay: 0.1 } },
};

const subheadingVariants: Variants = {
    default: { opacity: 0 },
    reveal: { opacity: 1, transition: { ease: 'easeInOut', duration: 1.8, delay: 0.3 } },
};

export function Homepage() {
    return (
        <div css={[t.fixed, t.inset_0, t.overflow_y_auto]}>
            <Helmet>
                <title>Colin Riley</title>
            </Helmet>
            <HomepageHeader />
            <HomepageProjects />
            <HomepageSkills />
            <HomepageTimeline />
            <HomepageQuotes />
            <Footer />
        </div>
    );
}

function HomepageHeader() {
    return (
        <motion.div css={[t.bg_dark_2, t.flex, t.flex_col]} initial="default" animate="reveal" variants={containerVariants}>
            <Page>
                <div css={[t.flex, t.flex_col, t.h('40vh'), t.sm([t.h('60vh')]), t.md([t.h('80vh')])]}>
                    <div css={[t.flex_auto, t.flex, t.flex_col, t.justify_center, t.items_center, t.text_primary_1]}>
                        <LogoIcon thin style={[t.size('80px'), t.sm([t.size('128px')]), t.md([t.size('148px')])]} />
                    </div>
                    <div css={[t.flex_initial, t.flex, t.flex_col, t.justify_center, t.items_center, t.mb_6, t.sm([t.mb_8]), t.md([t.mb_9])]}>
                        <motion.h1
                            css={[t.font_heading, t.font_extra_light, t.text_tint_5, t.type_5, t.tracking_widest, t.sm([t.type_7]), t.md([t.type_8])]}
                            variants={headingVariants}
                        >
                            COLIN RILEY
                        </motion.h1>
                        <motion.span
                            css={[t.font_heading, t.font_normal, t.text_tint_5, t.type_0, t.tracking_widest, t.sm([t.type_2]), t.md([t.type_3])]}
                            variants={subheadingVariants}
                        >
                            DESIGNER / PROTOTYPER
                        </motion.span>
                    </div>
                </div>
            </Page>
        </motion.div>
    );
}
