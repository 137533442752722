/** @jsxImportSource @emotion/react */
import React from 'react';
import { Link } from 'react-router-dom';
import t, { CSSStyle } from '../theme/styles';

export function TextRouteLink(props: { to: string; label: string; style?: CSSStyle; outerStyle?: CSSStyle }) {
    return (
        <div css={[props.outerStyle]}>
            <Link to={props.to} css={[t.text_inherit, t.no_underline, t.border_b, t.border_solid, t.py_1, t.visited([t.text_inherit]), props.style]}>
                {props.label}
            </Link>
        </div>
    );
}
