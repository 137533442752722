/** @jsxImportSource @emotion/react */
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import t, { CSSStyle } from '../../theme/styles';

export function Carousel(props: { children?: ReactNode; style?: CSSStyle }) {
    const carousel = useCarousel();

    useEffect(() => {
        carousel.setSlideCount(React.Children.count(props.children));
    }, [carousel, props.children]);

    return <div css={[t.pageWidth, props.style]}>{props.children}</div>;
}

export interface CarouselState {
    activeIndex: number;
    slideCount: number;
    setActiveIndex: (index: number) => void;
    setSlideCount: (count: number) => void;
    next: () => void;
    previous: () => void;
}

export const CarouselContext = createContext({} as CarouselState);
export const CarouselProvider = (props: { children: React.ReactNode }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [slideCount, setSlideCount] = useState(0);

    const callSetActiveIndex = (index: number) => {
        setActiveIndex(index);
    };

    const callSetSlideCount = (count: number) => {
        setSlideCount(count);
    };

    const next = () => {
        if (activeIndex === slideCount - 1) setActiveIndex(0);
        else setActiveIndex(activeIndex + 1);
    };

    const previous = () => {
        if (activeIndex === 0) setActiveIndex(slideCount - 1);
        else setActiveIndex(activeIndex - 1);
    };

    return (
        <CarouselContext.Provider
            value={{
                activeIndex: activeIndex,
                slideCount: slideCount,
                setActiveIndex: callSetActiveIndex,
                setSlideCount: callSetSlideCount,
                next: next,
                previous: previous,
            }}
        >
            {props.children}
        </CarouselContext.Provider>
    );
};

export function useCarousel() {
    return useContext(CarouselContext);
}
