import { css } from '@emotion/react';
import { palette } from './palette';
import { background_color } from './utilities';

export const background = {
    bg_transparent: css`
        background-color: transparent;
    `,
    bg_black: css`
        background-color: black;
    `,
    bg_white: css`
        background-color: white;
    `,
    bg_color: background_color,
    bg_dark_1: background_color(palette.dark_1),
    bg_dark_2: background_color(palette.dark_2),
    bg_dark_3: background_color(palette.dark_3),
    bg_dark_4: background_color(palette.dark_4),
    bg_primary_1: background_color(palette.primary_1),
    bg_tint_1: background_color(palette.tint_1),
    bg_tint_2: background_color(palette.tint_2),
    bg_tint_3: background_color(palette.tint_3),
    bg_tint_4: background_color(palette.tint_4),
    bg_tint_5: background_color(palette.tint_5),
    bg_error_1: background_color(palette.error_1),
    bg_error_2: background_color(palette.error_2),
    bg_success: background_color(palette.success),
};
