/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';
import t, { CSSStyle } from '../theme/styles';

export function ImageGallery(props: { columns?: number; style?: CSSStyle; children?: ReactNode }) {
    let gridStyle: CSSStyle = [t.grid_1, t.grid_gap_x5];
    if (props.columns !== undefined) {
        if (props.columns === 2) {
            gridStyle = [t.grid_1, t.grid_gap_y4, t.grid_gap_x6, t.md([t.grid_2, t.grid_gap_y7])];
        } else if (props.columns === 3) {
            gridStyle = [t.grid_1, t.grid_gap_y4, t.grid_gap_x6, t.sm([t.grid_2]), t.md([t.grid_3])];
        } else if (props.columns === 4) {
            gridStyle = [t.grid_1, t.grid_gap_y4, t.grid_gap_x6, t.sm([t.grid_2]), t.md([t.grid_4])];
        }
    }
    return <div css={[t.max_w_full, t.mt_4, t.mb_3, gridStyle, props.style]}>{props.children}</div>;
}
