/** @jsxImportSource @emotion/react */
import React from 'react';
import t from '../../theme/styles';
import { motion, Variants } from 'framer-motion';

const containerVariants: Variants = {
    default: { opacity: 0 },
    reveal: { opacity: 1, transition: { ease: 'easeInOut', duration: 1.8 } },
};

export function ProjectBanner(props: { src: string; alt: string }) {
    return (
        <div>
            <motion.div css={[t.relative, t.w_screen, t.max_w_screen, t.bg_sz_cover, t.bg_no_repeat, t.h('480px')]}>
                <motion.div css={[t.absolute, t.inset_0, t.bg_dark_1]} initial="default" animate="reveal" variants={containerVariants}>
                    <picture>
                        <img src={props.src} alt={props.alt} css={[t.w_full, t.h_full, t.object_cover]} />
                    </picture>
                </motion.div>
            </motion.div>
        </div>
    );
}
