/** @jsxImportSource @emotion/react */
import React from 'react';
import t from '../../theme/styles';

import { ProjectSectionContainer, ProjectSection } from '../projects/ProjectSection';
import { ThemeProvider } from '../../contexts/ThemeContext';
import { ProjectData } from '../../data/ProjectData';
import { ProjectLargeCard } from '../projects/ProjectLargeCard';

const focusProjects = ['dynamic-wall', 'popcap-projects', 'photo-agent'];
const headings = ['Tools Development & React', 'Prototyping & System Design', 'Games Experience'];

export function PortfolioReviewFooter() {
    return (
        <ThemeProvider tint="extra-dark">
            <ProjectSectionContainer>
                <ProjectSection>
                    <div css={[t.flex_auto, t.pt_7, t.pb_6, t.grid_gap_6, t.grid_1, t.md([t.grid_gap_7]), t.lg([t.grid_3, t.grid_gap_y5]), t.xl([t.grid_gap_x7, t.grid_gap_y6])]}>
                        {focusProjects.map((projectKey: string, index: number) => {
                            if (index >= 8) return null;
                            const project = ProjectData[projectKey];
                            return (
                                <div key={projectKey} css={[t.flex, t.flex_col]}>
                                    <h3 css={[t.flex, t.justify_center, t.type_3, t.font_extra_light, t.mb_3, t.text_tint_4]}>{headings[index]}</h3>
                                    <ProjectLargeCard index={index} label={project.Name} to={`/portfolio-review/${projectKey}`} src={project.Src} alt={project.Alt} />
                                </div>
                            );
                        })}
                    </div>
                </ProjectSection>
            </ProjectSectionContainer>
        </ThemeProvider>
    );
}
