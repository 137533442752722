import { css } from '@emotion/react';

export const pointerEvents = {
    pointer_events_none: css`
        pointer-events: none;
    `,
    pointer_events_auto: css`
        pointer-events: auto;
    `,
};
