import { css } from '@emotion/react';

export function transform_skewX(deg: number) {
    return css`
        transform: skewX(${deg}deg);
    `;
}

export function transform_skewY(deg: number) {
    return css`
        transform: skewY(${deg}deg);
    `;
}

export const skew = {
    skew_Y: transform_skewY,
    skew_X: transform_skewX,
};
