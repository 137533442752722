/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { useThemeController } from '../../contexts/ThemeContext';
import t, { CSSStyle } from '../../theme/styles';

export function TimelineEvent(props: {
    side: 'left' | 'right';
    date: string;
    location: string;
    firstEntry?: boolean;
    height?: number;
    children?: React.ReactNode;
    locationStyle?: CSSStyle;
}) {
    const theme = useThemeController();

    let dateTint = t.text_tint_5;
    let locationTint = t.text_tint_4;

    if (theme.tint) {
        switch (theme.tint) {
            case 'extra-light':
                dateTint = t.text_dark_2;
                locationTint = t.text_dark_4;
                break;
            case 'light':
                dateTint = t.text_dark_4;
                locationTint = t.text_dark_5;
                break;
            case 'dark':
                dateTint = t.text_tint_3;
                locationTint = t.text_tint_5;
                break;
            case 'extra-dark':
                dateTint = t.text_tint_5;
                locationTint = t.text_tint_4;
                break;
            case 'primary':
                dateTint = t.text_tint_5;
                locationTint = t.text_tint_5;
                break;
        }
    }

    return (
        <div css={[t.relative, props.firstEntry ? t.mb_0 : t.mb_7, t.flex, props.height ? t.mt(`${props.height}px`) : null]}>
            <TimelineLocation />
            <div css={[t.flex, t.flex_auto, props.side === 'left' ? [t.flex_row_reverse, t.text_right] : [t.flex_row]]}>
                <div css={[t.flex0, t.flex, props.side === 'left' ? t.justify_start : t.justify_end, t.type_0, t.font_normal, dateTint]}>{props.date}</div>
                <div css={[t.flex_initial, t.w_9]} />
                <div css={[t.flex0, t.flex, t.flex_col, t.mt('-4px'), props.side === 'left' ? t.items_end : t.items_start]}>
                    <div css={[t.type_2, t.font_extra_light, locationTint, t.mb_1, props.locationStyle]}>{props.location}</div>
                    <ul css={[t.flex, t.flex_col, t.justify_end, t.items_end]}>{props.children}</ul>
                </div>
            </div>
        </div>
    );
}

function TimelineLocation() {
    const theme = useThemeController();

    let bgTint = t.bg_dark_3;
    let borderTint = t.border_primary_1;

    if (theme.tint) {
        switch (theme.tint) {
            case 'extra-light':
                bgTint = t.bg_tint_3;
                borderTint = t.border_dark_4;
                break;
            case 'light':
                bgTint = t.bg_tint_4;
                borderTint = t.border_dark_3;
                break;
            case 'dark':
                bgTint = t.bg_dark_4;
                borderTint = t.border_tint_4;
                break;
            case 'extra-dark':
                bgTint = t.bg_dark_3;
                borderTint = t.border_tint_3;
                break;
            case 'primary':
                bgTint = t.bg_primary_1;
                borderTint = t.border_tint_5;
                break;
        }
    }

    return (
        <div
            css={[
                t.absolute,
                t.top_0,
                t.left('50%'),
                t.size('16px'),
                t.ml('-8px'),
                bgTint,
                t.border_3,
                t.border_solid,
                borderTint,
                css`
                    transform: rotate(45deg);
                `,
            ]}
        ></div>
    );
}
