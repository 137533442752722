import { css } from '@emotion/react';

export const fontWeight = {
    font_extra_light: css`
        font-weight: 200;
    `,
    font_normal: css`
        font-weight: 400;
    `,
    font_bold: css`
        font-weight: 700;
    `,
};
