/** @jsxImportSource @emotion/react */
import React from 'react';
import { Page } from '../../components/page';
import t from '../../theme/styles';

export function HomepageSkills() {
    return (
        <div css={[t.bg_dark_4]}>
            <Page style={[t.flex, t.flex_wrap]}>
                <div css={[t.w_full, t.mb_7, t.grid_1, t.grid_gap_1, t.md([t.grid_2, t.grid_gap_5]), t.lg([t.grid_gap_9])]}>
                    <div css={[t.mt_7]}>
                        <SkillsHeading label="Skills" />

                        <div css={[t.w_full, t.grid_2, t.pt_3]}>
                            <ul css={[t.flex, t.flex_col]}>
                                <SkillsListItem label="UX Design" />
                                <SkillsListItem label="Prototyping" />
                                <SkillsListItem label="Collaboration" />
                                <SkillsListItem label="UI Design" />
                            </ul>
                            <ul css={[t.flex, t.flex_col]}>
                                <SkillsListItem label="Scripting" />
                                <SkillsListItem label="Agile Development" />
                                <SkillsListItem label="Usability Testing" />
                                <SkillsListItem label="Game Design" />
                            </ul>
                        </div>
                    </div>
                    <div css={[t.mt_7]}>
                        <SkillsHeading label="Tools" />
                        <div css={[t.w_full, t.grid_2, t.pt_3]}>
                            <ul css={[t.flex, t.flex_col]}>
                                <SkillsListItem label="Unity" />
                                <SkillsListItem label="React" />
                                <SkillsListItem label="Photoshop" />
                                <SkillsListItem label="Typescript" />
                            </ul>
                            <ul css={[t.flex, t.flex_col]}>
                                <SkillsListItem label="Illustrator" />
                                <SkillsListItem label="C#" />
                                <SkillsListItem label="Figma" />
                                <SkillsListItem label="Blender" />
                            </ul>
                        </div>
                    </div>
                </div>
            </Page>
        </div>
    );
}

function SkillsHeading(props: { label: string }) {
    return (
        <div css={[t.border_b, t.border_solid, t.border_tint_5]}>
            <div css={[t.font_heading, t.font_normal, t.type_1, t.text_tint_3, t.mb_1]}>{props.label}</div>
        </div>
    );
}

function SkillsListItem(props: { label: string }) {
    return <li css={[t.font_heading, t.font_extra_light, t.type_1, t.text_tint_3, t.mb_3]}>{props.label}</li>;
}
