/** @jsxImportSource @emotion/react */
import React from 'react';
import { useThemeController } from '../../contexts/ThemeContext';
import t from '../../theme/styles';

export function JobTitleListItem(props: { title: string }) {
    const theme = useThemeController();

    let textTint = t.text_tint_3;

    if (theme.tint) {
        switch (theme.tint) {
            case 'extra-light':
                textTint = t.text_dark_4;
                break;
            case 'light':
                textTint = t.text_dark_5;
                break;
            case 'dark':
                textTint = t.text_tint_4;
                break;
            case 'extra-dark':
                textTint = t.text_tint_3;
                break;
            case 'primary':
                textTint = t.text_tint_5;
                break;
        }
    }
    return <li css={[t.type_0, t.font_normal, textTint, t.mb_1]}>{props.title}</li>;
}
