import { css } from '@emotion/react';
import { CSSStyle } from './styles';

function SelectorBuilder(selector: string, styles: CSSStyle) {
    const style: { [key: string]: CSSStyle } = {};
    style[selector] = styles;
    return style;
}

export const pseudoClass = {
    hover: (styles: CSSStyle) => {
        return css`
            &:hover {
                ${styles}
            }
        `;
    },
    hoverSelector: (selection: string, styles: CSSStyle) => {
        return SelectorBuilder(`&:hover ${selection}`, styles);
    },
    active: (styles: CSSStyle) => {
        return { '&:active': styles };
    },
    activeSelector: (selection: string, styles: CSSStyle) => {
        return SelectorBuilder(`&:active ${selection}`, styles);
    },
    focus: (styles: CSSStyle) => {
        return { '&:focus': styles };
    },
    focusSelector: (selection: string, styles: CSSStyle) => {
        return SelectorBuilder(`&:focus ${selection}`, styles);
    },
    focus_within: (styles: CSSStyle) => {
        return { '&:focus-within': styles };
    },
    visited: (styles: CSSStyle) => {
        return css`
            &:visited {
                ${styles}
            }
        `;
    },
    disabled: (styles: CSSStyle) => {
        return { '&:disabled': styles };
    },
    disabledSelector: (selection: string, styles: CSSStyle) => {
        return SelectorBuilder(`&:disabled ${selection}`, styles);
    },
    placeholder: (styles: CSSStyle) => {
        return { '&::placeholder': styles };
    },
    checked: (styles: CSSStyle) => {
        return { '&:checked': styles };
    },
    checkedSelector: (selection: string, styles: CSSStyle) => {
        return SelectorBuilder(`&:checked ${selection}`, styles);
    },
    first: (styles: CSSStyle) => {
        return { '&:first-child': styles };
    },
    firstType: (type: string, styles: CSSStyle) => {
        return SelectorBuilder(`& > ${type}:first-of-type`, styles);
    },
    lastChild: (styles: CSSStyle) => {
        return css`
            &:last-child {
                ${styles}
            }
        `;
    },
    lastType: (type: string, styles: CSSStyle) => {
        return SelectorBuilder(`& > ${type}:last-of-type`, styles);
    },
    before: (styles: CSSStyle) => {
        return css`
            &::before {
                ${styles}
            }
        `;
    },
    after: (styles: CSSStyle) => {
        return css`
            &::after {
                ${styles}
            }
        `;
    },
    child: (child: string, styles: CSSStyle) => {
        return css`
            & > ${child} {
                ${styles}
            }
        `;
    },
    customSelector: SelectorBuilder,
};
