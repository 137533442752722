import {css} from '@emotion/react';

export const fontFamily = {
  font_heading: css`
  font-family: 'Raleway', sans-serif;`,

  font_body: css`
  font-family: 'Montserrat', sans-serif;
  `

}