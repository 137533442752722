import { css } from '@emotion/react';

export const objectFit = {
    object_contain: css`
        object-fit: contain;
    `,
    object_cover: css`
        object-fit: cover;
    `,
    object_fill: css`
        object-fit: fill;
    `,
    object_none: css`
        object-fit: none;
    `,
    object_scale_down: css`
        object-fit: scale-down;
    `,
};
