import { css } from '@emotion/react';

export const display = {
    block: css`
        display: block;
    `,
    inline_block: css`
        display: inline-block;
    `,
    inline: css`
        display: inline;
    `,
    flex: css`
        display: flex;
    `,
    inline_flex: css`
        display: inline-flex;
    `,
    table: css`
        display: table;
    `,
    table_row: css`
        display: table-row;
    `,
    table_cell: css`
        display: table-cell;
    `,
    hidden: css`
        display: none;
    `,
    grid: css`
        display: grid;
    `,
};
