/** @jsxImportSource @emotion/react */
import React from 'react';
import { useThemeController } from '../../contexts/ThemeContext';
import t from '../../theme/styles';

export function TimelineSpine() {
    const theme = useThemeController();

    let bgTint = t.bg_primary_1;

    if (theme.tint) {
        switch (theme.tint) {
            case 'extra-light':
                bgTint = t.bg_dark_4;
                break;
            case 'light':
                bgTint = t.bg_dark_3;
                break;
            case 'dark':
                bgTint = t.bg_dark_4;
                break;
            case 'extra-dark':
                bgTint = t.bg_dark_3;
                break;
            case 'primary':
                bgTint = t.bg_primary_1;
                break;
        }
    }
    return <div css={[t.absolute, t.top_0, t.left('50%'), t.w('3px'), t.ml('-1.5px'), t.h_full, bgTint]}></div>;
}
