/** @jsxImportSource @emotion/react */
import React, { ForwardedRef, forwardRef, useState } from 'react';
import t, { CSSStyle } from '../../theme/styles';
import { motion, Variants } from 'framer-motion';
import { useCarousel } from './Carousel';

const buttonVariants: Variants = {
    hover: { opacity: 1 },
    unhover: { opacity: 0.6 },
    focus: {},
    blur: {},
};

const chevronVariants: Variants = {
    hover: (direction: NavDirection) => ({ x: direction === 'next' ? 8 : -8, scale: 1.1 }),
    unhover: { x: 0, scale: 1 },
    focus: {},
    blur: {},
};

type NavDirection = 'next' | 'previous';

export function CarouselNavigationButton(props: { direction: NavDirection }) {
    const { next, previous } = useCarousel();

    const chevronStyle: CSSStyle = [t.bg_tint_1, t.w('4px'), t.h('28px')];
    if (props.direction === 'next')
        return (
            <MotionNavButton onClick={() => next()} direction={props.direction}>
                <div css={[chevronStyle, t.skew_X(30)]} />
                <div css={[chevronStyle, t.skew_X(-30)]} />
            </MotionNavButton>
        );
    else
        return (
            <MotionNavButton onClick={() => previous()} direction={props.direction}>
                <div css={[chevronStyle, t.skew_X(-30)]} />
                <div css={[chevronStyle, t.skew_X(30)]} />
            </MotionNavButton>
        );
}

const NavButton = forwardRef((props: { direction: NavDirection; children?: React.ReactNode; onClick?: () => void }, ref: ForwardedRef<HTMLButtonElement | null>) => {
    const [focus, SetFocus] = useState(false);
    const [hover, SetHover] = useState(false);

    return (
        <motion.button
            ref={ref}
            onClick={props.onClick}
            onFocus={() => SetFocus(true)}
            onBlur={() => SetFocus(false)}
            onMouseEnter={() => SetHover(true)}
            onMouseLeave={() => SetHover(false)}
            initial={['unhover', 'blur']}
            animate={[hover ? 'hover' : 'unhover', focus ? 'focus' : 'blur']}
            variants={buttonVariants}
            css={[
                t.relative,
                t.cursor_pointer,
                t.bg_transparent,
                t.font_heading,
                t.text_inherit,
                t.type_5,
                t.px_3,
                t.py_4,
                t.outline_none,
                t.after([focus ? t.content_some : t.content_none, t.absolute, t.inset_0, t.border_2, t.border_solid, t.border_tint_1, t.opacity_40, t.rounded_sm]),
                t.md([t.px_5]),
            ]}
        >
            <motion.div css={[t.flex, t.flex_col]} variants={chevronVariants} custom={props.direction}>
                {props.children}
            </motion.div>
        </motion.button>
    );
});

const MotionNavButton = motion(NavButton);

// (props: { children?: React.ReactNode; onClick?: () => void }) {
//     const [focus, SetFocus] = useState(false);
//     const [hover, SetHover] = useState(false);

//     return (
//         <motion.button
//             onClick={props.onClick}
//             onFocus={() => SetFocus(true)}
//             onBlur={() => SetFocus(false)}
//             onMouseEnter={() => SetHover(true)}
//             onMouseLeave={() => SetHover(false)}
//             initial={['unhover', 'blur']}
//             animate={[hover ? 'hover' : 'unhover', focus ? 'focus' : 'blur']}
//             variants={buttonVariants}
//             css={[
//                 t.relative,
//                 t.cursor_pointer,
//                 t.bg_transparent,
//                 t.font_heading,
//                 t.text_inherit,
//                 t.type_5,
//                 t.px_3,
//                 t.py_4,
//                 t.outline_none,
//                 t.after([focus ? t.content_some : t.content_none, t.absolute, t.inset_0, t.border_2, t.border_solid, t.border_tint_1, t.opacity_40, t.rounded_sm]),
//                 t.md([t.px_5]),
//             ]}
//         >
//             {props.children}
//         </motion.button>
//     );
// }
