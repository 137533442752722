/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';
import t from '../../theme/styles';
import { Helmet } from 'react-helmet-async';

export function ProjectPage(props: { title?: string; children?: ReactNode }) {
    return (
        <div css={[t.bg_tint_1]}>
            {props.title && (
                <Helmet>
                    <title>Colin Riley | {props.title}</title>
                </Helmet>
            )}
            {props.children}
        </div>
    );
}
