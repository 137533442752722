/** @jsxImportSource @emotion/react */
import React from 'react';
import t, { CSSStyle } from '../theme/styles';

export function TextLink(props: { href: string; label: string; style?: CSSStyle }) {
    return (
        <div>
            <a href={props.href} target="_blank" rel="noreferrer" css={[t.type_2, t.font_extra_light, t.no_underline, t.text_primary_1, t.hover([t.underline])]}>
                {props.label}
            </a>
        </div>
    );
}
