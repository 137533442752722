import { css } from '@emotion/react';
import { mx, my, mt, mr, mb, ml } from './utilities';
import { spacing } from './spacing';

export const margin = {
    mt_header: css`
        margin-top: 56px;
    `,
    m_0: css`
        margin: ${spacing.s0};
    `,
    m_1: css`
        margin: ${spacing.s1};
    `,
    m_2: css`
        margin: ${spacing.s2};
    `,
    m_3: css`
        margin: ${spacing.s3};
    `,
    m_4: css`
        margin: ${spacing.s4};
    `,
    m_5: css`
        margin: ${spacing.s5};
    `,
    m_6: css`
        margin: ${spacing.s6};
    `,
    m_7: css`
        margin: ${spacing.s7};
    `,
    m_8: css`
        margin: ${spacing.s8};
    `,
    m_9: css`
        margin: ${spacing.s9};
    `,
    m_10: css`
        margin: ${spacing.s10};
    `,
    m_auto: css`
        margin: auto;
    `,
    mt_0: css`
        margin-top: ${spacing.s0};
    `,
    mt_1: css`
        margin-top: ${spacing.s1};
    `,
    mt_2: css`
        margin-top: ${spacing.s2};
    `,
    mt_3: css`
        margin-top: ${spacing.s3};
    `,
    mt_4: css`
        margin-top: ${spacing.s4};
    `,
    mt_5: css`
        margin-top: ${spacing.s5};
    `,
    mt_6: css`
        margin-top: ${spacing.s6};
    `,
    mt_7: css`
        margin-top: ${spacing.s7};
    `,
    mt_8: css`
        margin-top: ${spacing.s8};
    `,
    mt_9: css`
        margin-top: ${spacing.s9};
    `,
    mt_10: css`
        margin-top: ${spacing.s10};
    `,
    mr_0: css`
        margin-right: ${spacing.s0};
    `,
    mr_1: css`
        margin-right: ${spacing.s1};
    `,
    mr_2: css`
        margin-right: ${spacing.s2};
    `,
    mr_3: css`
        margin-right: ${spacing.s3};
    `,
    mr_4: css`
        margin-right: ${spacing.s4};
    `,
    mr_5: css`
        margin-right: ${spacing.s5};
    `,
    mr_6: css`
        margin-right: ${spacing.s6};
    `,
    mr_7: css`
        margin-right: ${spacing.s7};
    `,
    mr_8: css`
        margin-right: ${spacing.s8};
    `,
    mr_9: css`
        margin-right: ${spacing.s9};
    `,
    mr_10: css`
        margin-right: ${spacing.s10};
    `,
    mb_0: css`
        margin-bottom: ${spacing.s0};
    `,
    mb_1: css`
        margin-bottom: ${spacing.s1};
    `,
    mb_2: css`
        margin-bottom: ${spacing.s2};
    `,
    mb_3: css`
        margin-bottom: ${spacing.s3};
    `,
    mb_4: css`
        margin-bottom: ${spacing.s4};
    `,
    mb_5: css`
        margin-bottom: ${spacing.s5};
    `,
    mb_6: css`
        margin-bottom: ${spacing.s6};
    `,
    mb_7: css`
        margin-bottom: ${spacing.s7};
    `,
    mb_8: css`
        margin-bottom: ${spacing.s8};
    `,
    mb_9: css`
        margin-bottom: ${spacing.s9};
    `,
    mb_10: css`
        margin-bottom: ${spacing.s10};
    `,
    ml_0: css`
        margin-left: ${spacing.s0};
    `,
    ml_1: css`
        margin-left: ${spacing.s1};
    `,
    ml_2: css`
        margin-left: ${spacing.s2};
    `,
    ml_3: css`
        margin-left: ${spacing.s3};
    `,
    ml_4: css`
        margin-left: ${spacing.s4};
    `,
    ml_5: css`
        margin-left: ${spacing.s5};
    `,
    ml_6: css`
        margin-left: ${spacing.s6};
    `,
    ml_7: css`
        margin-left: ${spacing.s7};
    `,
    ml_8: css`
        margin-left: ${spacing.s8};
    `,
    ml_9: css`
        margin-left: ${spacing.s9};
    `,
    ml_10: css`
        margin-left: ${spacing.s10};
    `,
    mt: mt,
    mr: mr,
    mb: mb,
    ml: ml,
    mx: mx,
    mx_0: mx(spacing.s0),
    mx_1: mx(spacing.s1),
    mx_2: mx(spacing.s2),
    mx_3: mx(spacing.s3),
    mx_4: mx(spacing.s4),
    mx_5: mx(spacing.s5),
    mx_6: mx(spacing.s6),
    mx_7: mx(spacing.s7),
    mx_8: mx(spacing.s8),
    mx_9: mx(spacing.s9),
    mx_10: mx(spacing.s10),
    mx_auto: mx('auto'),
    my: my,
    my_0: my(spacing.s0),
    my_1: my(spacing.s1),
    my_2: my(spacing.s2),
    my_3: my(spacing.s3),
    my_4: my(spacing.s4),
    my_5: my(spacing.s5),
    my_6: my(spacing.s6),
    my_7: my(spacing.s7),
    my_8: my(spacing.s8),
    my_9: my(spacing.s9),
    my_10: my(spacing.s10),
    my_auto: my('auto'),
};
