/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';
import t, { CSSStyle } from '../../theme/styles';
import { motion, Variants } from 'framer-motion';

const outerVariants: Variants = {
    default: { opacity: 0 },
    reveal: { opacity: 1, transition: { ease: 'easeInOut', duration: 0.6, delay: 0.2 } },
};

const containerVariants: Variants = {
    default: { y: -30 },
    reveal: { y: 0, transition: { ease: 'easeInOut', duration: 1.2, delay: 0.2 } },
};

const headingVariants: Variants = {
    default: { opacity: 0 },
    reveal: { opacity: 1, transition: { duration: 0.6, delay: 0.7 } },
};

const subheadingVariants: Variants = {
    default: { opacity: 0 },
    reveal: { opacity: 1, transition: { duration: 0.6, delay: 0.9 } },
};

const childVariants: Variants = {
    default: { opacity: 0 },
    reveal: { opacity: 1, transition: { duration: 0.6, delay: 1 } },
};

export function ProjectAside(props: { title: string; company?: string; date: string; titleStyle?: CSSStyle; children?: ReactNode }) {
    return (
        <motion.div
            css={[t.absolute, t.top_0, t.inset_x_0, t.md(t.left_unset), t.z_10, t.flex_initial, t.flex, t.flex_col]}
            initial="default"
            animate="reveal"
            variants={outerVariants}
        >
            <motion.div css={[t.w_full, t.md([t.w('248px'), t.mr_3]), t.mt('-96px'), t.relative]} variants={containerVariants}>
                <div css={[t.bg_primary_1, t.absolute, t.inset_0, t.skew_Y(-2), t.md([t.skew_Y(-5)])]} />
                <div css={[t.relative, t.px_5, t.pt_6, t.pb_6, t.text_tint_1, t.sm([t.px_6]), t.md([t.pt_5, t.px_5])]}>
                    <motion.h1 css={[t.font_heading, t.font_extra_light, t.type_7, t.mb_1, props.titleStyle]} variants={headingVariants}>
                        {props.title}
                    </motion.h1>
                    <motion.div css={[t.font_heading, t.font_normal, t.type_0, t.mb_6]} variants={subheadingVariants}>
                        {props.company && `${props.company}\u00A0\u00A0|\u00A0\u00A0`}
                        {`${props.date}`}
                    </motion.div>
                    <motion.div variants={childVariants}>{props.children}</motion.div>
                </div>
            </motion.div>
        </motion.div>
    );
}
