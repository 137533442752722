import { css } from '@emotion/react';
import { px, py, pt, pr, pb, pl, p } from './utilities';
import { spacing } from './spacing';

export const padding = {
    pt_header: css`
        padding-top: 56px;
    `,
    p_0: css`
        padding: ${spacing.s0};
    `,
    p_1: css`
        padding: ${spacing.s1};
    `,
    p_2: css`
        padding: ${spacing.s2};
    `,
    p_3: css`
        padding: ${spacing.s3};
    `,
    p_4: css`
        padding: ${spacing.s4};
    `,
    p_5: css`
        padding: ${spacing.s5};
    `,
    p_6: css`
        padding: ${spacing.s6};
    `,
    p_7: css`
        padding: ${spacing.s7};
    `,
    p_8: css`
        padding: ${spacing.s8};
    `,
    p_9: css`
        padding: ${spacing.s9};
    `,
    p_10: css`
        padding: ${spacing.s10};
    `,
    pt_0: css`
        padding-top: ${spacing.s0};
    `,
    pt_1: css`
        padding-top: ${spacing.s1};
    `,
    pt_2: css`
        padding-top: ${spacing.s2};
    `,
    pt_3: css`
        padding-top: ${spacing.s3};
    `,
    pt_4: css`
        padding-top: ${spacing.s4};
    `,
    pt_5: css`
        padding-top: ${spacing.s5};
    `,
    pt_6: css`
        padding-top: ${spacing.s6};
    `,
    pt_7: css`
        padding-top: ${spacing.s7};
    `,
    pt_8: css`
        padding-top: ${spacing.s8};
    `,
    pt_9: css`
        padding-top: ${spacing.s9};
    `,
    pt_10: css`
        padding-top: ${spacing.s10};
    `,
    pr_0: css`
        padding-right: ${spacing.s0};
    `,
    pr_1: css`
        padding-right: ${spacing.s1};
    `,
    pr_2: css`
        padding-right: ${spacing.s2};
    `,
    pr_3: css`
        padding-right: ${spacing.s3};
    `,
    pr_4: css`
        padding-right: ${spacing.s4};
    `,
    pr_5: css`
        padding-right: ${spacing.s5};
    `,
    pr_6: css`
        padding-right: ${spacing.s6};
    `,
    pr_7: css`
        padding-right: ${spacing.s7};
    `,
    pr_8: css`
        padding-right: ${spacing.s8};
    `,
    pr_9: css`
        padding-right: ${spacing.s9};
    `,
    pr_10: css`
        padding-right: ${spacing.s10};
    `,
    pb_0: css`
        padding-bottom: ${spacing.s0};
    `,
    pb_1: css`
        padding-bottom: ${spacing.s1};
    `,
    pb_2: css`
        padding-bottom: ${spacing.s2};
    `,
    pb_3: css`
        padding-bottom: ${spacing.s3};
    `,
    pb_4: css`
        padding-bottom: ${spacing.s4};
    `,
    pb_5: css`
        padding-bottom: ${spacing.s5};
    `,
    pb_6: css`
        padding-bottom: ${spacing.s6};
    `,
    pb_7: css`
        padding-bottom: ${spacing.s7};
    `,
    pb_8: css`
        padding-bottom: ${spacing.s8};
    `,
    pb_9: css`
        padding-bottom: ${spacing.s9};
    `,
    pb_10: css`
        padding-bottom: ${spacing.s10};
    `,
    pl_0: css`
        padding-left: ${spacing.s0};
    `,
    pl_1: css`
        padding-left: ${spacing.s1};
    `,
    pl_2: css`
        padding-left: ${spacing.s2};
    `,
    pl_3: css`
        padding-left: ${spacing.s3};
    `,
    pl_4: css`
        padding-left: ${spacing.s4};
    `,
    pl_5: css`
        padding-left: ${spacing.s5};
    `,
    pl_6: css`
        padding-left: ${spacing.s6};
    `,
    pl_7: css`
        padding-left: ${spacing.s7};
    `,
    pl_8: css`
        padding-left: ${spacing.s8};
    `,
    pl_9: css`
        padding-left: ${spacing.s9};
    `,
    pl_10: css`
        padding-left: ${spacing.s10};
    `,
    p: p,
    pt: pt,
    pr: pr,
    pb: pb,
    pl: pl,
    px: px,
    px_0: px(spacing.s0),
    px_1: px(spacing.s1),
    px_2: px(spacing.s2),
    px_3: px(spacing.s3),
    px_4: px(spacing.s4),
    px_5: px(spacing.s5),
    px_6: px(spacing.s6),
    px_7: px(spacing.s7),
    px_8: px(spacing.s8),
    px_9: px(spacing.s9),
    px_10: px(spacing.s10),
    py: py,
    py_0: py(spacing.s0),
    py_1: py(spacing.s1),
    py_2: py(spacing.s2),
    py_3: py(spacing.s3),
    py_4: py(spacing.s4),
    py_5: py(spacing.s5),
    py_6: py(spacing.s6),
    py_7: py(spacing.s7),
    py_8: py(spacing.s8),
    py_9: py(spacing.s9),
    py_10: py(spacing.s10),
};
