import { css } from '@emotion/react';
import { palette } from './palette';
import { text_color } from './utilities';

export const textColor = {
    text_inherit: css`
        color: inherit;
    `,
    text_transparent: css`
        color: transparent;
    `,
    text_black: css`
        color: #000;
    `,
    text_white: css`
        color: #fff;
    `,
    text_color: text_color,
    text_dark_1: text_color(palette.dark_1),
    text_dark_2: text_color(palette.dark_2),
    text_dark_3: text_color(palette.dark_3),
    text_dark_4: text_color(palette.dark_4),
    text_dark_5: text_color(palette.dark_5),
    text_primary_1: text_color(palette.primary_1),
    text_tint_1: text_color(palette.tint_1),
    text_tint_2: text_color(palette.tint_2),
    text_tint_3: text_color(palette.tint_3),
    text_tint_4: text_color(palette.tint_4),
    text_tint_5: text_color(palette.tint_5),
    text_error_1: text_color(palette.error_1),
    text_error_2: text_color(palette.error_2),
};
