/** @jsxImportSource @emotion/react */
import React from 'react';
import { Page } from '../../components/page';
import { TextLink } from '../../components/TextLink';
import { JobTitleListItem } from '../../components/Timeline/JobTitleListItem';
import { TimelineEvent } from '../../components/Timeline/TimelineEvent';
import { TimelineSpine } from '../../components/Timeline/TimelineSpine';
import t from '../../theme/styles';

export function HomepageTimeline() {
    return (
        <div css={[t.bg_dark_3, t.pt_9, t.pb_7]}>
            <Page>
                <div css={[t.relative]}>
                    <TimelineSpine />
                    <div css={[t.flex, t.flex_col]}>
                        <TimelineEvent side="right" date="2023 - 2024" location="Midwinter Entertainment">
                            <ul>
                                <JobTitleListItem title="Senior Technical UI Designer" />
                            </ul>
                        </TimelineEvent>
                        <TimelineEvent side="left" date="2021 - 2023" location="Unity Technologies">
                            <JobTitleListItem title="Senior Product Designer" />
                        </TimelineEvent>
                        <TimelineEvent side="right" date="2019 - 2021" location="Pilot Labs" height={48}>
                            <JobTitleListItem title="Senior Design Technologist" />
                        </TimelineEvent>
                        <TimelineEvent side="left" date="2015 - 2019" location="PopCap Games" height={48}>
                            <JobTitleListItem title="Senior UX Designer" />
                        </TimelineEvent>
                        <TimelineEvent side="right" date="2014 - 2015" location="Amazon Game Studios">
                            <JobTitleListItem title="UX Designer" />
                        </TimelineEvent>
                        <TimelineEvent side="left" date="2013 - 2014" location="Intentional Futures">
                            <JobTitleListItem title="Senior Designer" />
                        </TimelineEvent>
                        <TimelineEvent side="right" date="2012 - 2013" location="Amazon Game Studios">
                            <JobTitleListItem title="UX Designer" />
                        </TimelineEvent>
                        <TimelineEvent side="left" date="2006 - 2012" location="Microsoft" height={80}>
                            <JobTitleListItem title="Design Development Lead" />
                            <JobTitleListItem title="Technical Artist" />
                            <JobTitleListItem title="UI Artist" />
                        </TimelineEvent>
                    </div>
                </div>
                <TimelineEvent firstEntry side="right" date="1999 - 2004" location="University of Nebraska - Lincoln">
                    <ul>
                        <JobTitleListItem title="B.S. Computer Engineering" />
                    </ul>
                </TimelineEvent>
            </Page>
            <div css={[t.mt_7, t.flex, t.justify_center]}>
                <TextLink href="/assets/ColinRiley_resume.pdf" label="Printable Resume" />
            </div>
            {/* <div css={[t.mt_7, t.mb_5, t.flex, t.justify_center, t.text_primary_1]}>
                <TextRouteLink to="/portfolio-review" label="Portfolio Review" />
            </div> */}
        </div>
    );
}
