/** @jsxImportSource @emotion/react */
import React from 'react';
import { ImageGallery } from '../../../components/imageGallery';
import { ImageWithCaption } from '../../../components/imageWithCaption';
import { ThemeProvider } from '../../../contexts/ThemeContext';
import t from '../../../theme/styles';
import { ProjectArticle } from '../ProjectArticle';
import { ProjectAside } from '../ProjectAside';
import { ProjectBanner } from '../ProjectBanner';
import { ProjectOverview } from '../ProjectOverview';
import { ProjectPage } from '../ProjectPage';
import { ProjectSection, ProjectSectionContainer, ProjectSectionHeading } from '../ProjectSection';

export function ProjectTowerRaider2() {
    return (
        <ProjectPage title="Tower Raiders 2">
            <ProjectBanner src="/projects/TowerRaiders2/TR2_Header.jpg" alt="" />
            <ProjectArticle>
                <ProjectSectionContainer>
                    <ProjectOverview>
                        <ProjectSectionHeading label="Overview" />
                        <p>
                            A former co-worker from the 1 vs. 100 team, Chuck Noble, released his own tower defense game in the early days of Android. Before the launch of the
                            sequel, he reached out and asked if I could help him improve the interface.
                        </p>
                    </ProjectOverview>
                    <ProjectAside title="Tower Raiders 2" company="Gianormous Games" date="2011">
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Platform</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Android</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Tools</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Torque</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Skills</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>UX Design, UI Design, Integration</div>
                        </div>
                    </ProjectAside>
                </ProjectSectionContainer>
                <ThemeProvider tint="extra-light">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="My Role" />
                            <p>
                                I started with just a visual design pass but after learning enough about Torque's UI system I also did the implementation of the changes. With a
                                short time frame I was wary of changing too much about the UX so I kept my changes confined and minimized distruption.
                            </p>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
                <ThemeProvider tint="dark">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="UI/UX Revamp" />
                            <p>
                                As most of the interface was already contained within popup-like frames, I wanted to at least come up with a visual style for the frames that felt
                                more in line with the game's futuraristic theme. Once I established the brushed metal/hologram style I used that to design a new logo treatment. The
                                final bit of emphasis was that I wanted to improve the menu backgrounds while also trying to establish clearer boundaries between the game's UX
                                heirarchy. With the game's HUD already overlaying a planet's terrian, I made the menu system zoom away each step with the initial menu the farthest
                                away.
                            </p>

                            <ImageGallery columns={2} style={[t.grid_gap_y0, t.md([t.grid_2, t.grid_gap_y0])]}>
                                <ImageWithCaption src="/projects/TowerRaiders2/TR2_Original_MainMenu.jpg" alt="Original Main Menu" />
                                <ImageWithCaption src="/projects/TowerRaiders2/TR2_Updated_mainmenu2.jpg" alt="Redesigned Main Menu" />
                                <ImageWithCaption src="/projects/TowerRaiders2/TR2_Original_CampaignList.jpg" alt="Original Main Menu" />
                                <ImageWithCaption src="/projects/TowerRaiders2/TR2_Updated_campaignList.jpg" alt="Redesigned Main Menu" />
                                <ImageWithCaption src="/projects/TowerRaiders2/TR2_Original_RewardsList.jpg" alt="Original Main Menu" />
                                <ImageWithCaption src="/projects/TowerRaiders2/TR2_Updated_rewardsList.jpg" alt="Redesigned Main Menu" />
                                <ImageWithCaption src="/projects/TowerRaiders2/TR2_Original_TowerBuildMenu.jpg" alt="Original Main Menu" />
                                <ImageWithCaption src="/projects/TowerRaiders2/TR2_Updated_iPhone_inGame_alt2.jpg" alt="Redesigned Main Menu" />
                                <ImageWithCaption src="/projects/TowerRaiders2/TR2_Original_TowerRecycleMenu.jpg" alt="Original Main Menu" />
                                <ImageWithCaption src="/projects/TowerRaiders2/TR2_Updated_iPhone_inGame_alt3.jpg" alt="Redesigned Main Menu" />
                            </ImageGallery>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
            </ProjectArticle>
        </ProjectPage>
    );
}
