import { css } from '@emotion/react';
import { palette } from './palette';
import { border_color } from './utilities';

export const border = {
    border_solid: css`
        border-style: solid;
    `,
    border_dashed: css`
        border-style: dashed;
    `,
    border_dotted: css`
        border-style: dotted;
    `,
    border_none: css`
        border-style: none;
    `,
    border_transparent: css`
        border-color: transparent;
    `,
    border_black: css`
        border-color: black;
    `,
    border_white: css`
        border-color: white;
    `,
    border_color: border_color,
    border_dark_1: border_color(palette.dark_1),
    border_dark_2: border_color(palette.dark_2),
    border_dark_3: border_color(palette.dark_3),
    border_dark_4: border_color(palette.dark_4),
    border_primary_1: border_color(palette.primary_1),
    border_tint_1: border_color(palette.tint_1),
    border_tint_2: border_color(palette.tint_2),
    border_tint_3: border_color(palette.tint_3),
    border_tint_4: border_color(palette.tint_4),
    border_tint_5: border_color(palette.tint_5),
    border_error_1: border_color(palette.error_1),
    border_error_2: border_color(palette.error_2),
    rounded_none: css`
        border-radius: 0;
    `,
    rounded_sm: css`
        border-radius: 0.125rem;
    `,
    rounded: css`
        border-radius: 0.25rem;
    `,
    rounded_lg: css`
        border-radius: 0.5rem;
    `,
    rounded_full: css`
        border-radius: 9999px;
    `,
    border: css`
        border-width: 1px;
    `,
    border_0: css`
        border-width: 0;
    `,
    border_1: css`
        border-width: 1px;
    `,
    border_2: css`
        border-width: 2px;
    `,
    border_3: css`
        border-width: 3px;
    `,
    border_4: css`
        border-width: 4px;
    `,
    border_8: css`
        border-width: 8px;
    `,
    border_t: css`
        border-top-width: 1px;
    `,
    border_r: css`
        border-right-width: 1px;
    `,
    border_b: css`
        border-bottom-width: 1px;
    `,
    border_l: css`
        border-left-width: 1px;
    `,
    border_t_0: css`
        border-top-width: 0;
    `,
    border_r_0: css`
        border-right-width: 0;
    `,
    border_b_0: css`
        border-bottom-width: 0;
    `,
    border_l_0: css`
        border-left-width: 0;
    `,
    border_t_2: css`
        border-top-width: 2px;
    `,
    border_r_2: css`
        border-right-width: 2px;
    `,
    border_b_2: css`
        border-bottom-width: 2px;
    `,
    border_l_2: css`
        border-left-width: 2px;
    `,
    border_t_4: css`
        border-top-width: 4px;
    `,
    border_r_4: css`
        border-right-width: 4px;
    `,
    border_b_4: css`
        border-bottom-width: 4px;
    `,
    border_l_4: css`
        border-left-width: 4px;
    `,
    border_t_8: css`
        border-top-width: 8px;
    `,
    border_r_8: css`
        border-right-width: 8px;
    `,
    border_b_8: css`
        border-bottom-width: 8px;
    `,
    border_l_8: css`
        border-left-width: 8px;
    `,
    border_circle: css`
        border-radius: 50%;
    `,
};
