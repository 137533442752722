import { css } from '@emotion/react';
import { fontSize, lineHeight, textSize } from './utilities';

export const typeStyle = {
    fontSize: fontSize,
    lineHeight: lineHeight,
    textSize: textSize,

    type_0: css`
        font-size: 13px;
        line-height: 16px;
    `,
    type_1: css`
        font-size: 14px;
        line-height: 21px;
    `,
    type_2: css`
        font-size: 15px;
        line-height: 22px;
    `,
    type_3: css`
        font-size: 16px;
        line-height: 24px;
    `,
    type_4: css`
        font-size: 20px;
        line-height: 28px;
    `,
    type_5: css`
        font-size: 25px;
        line-height: 32px;
    `,
    type_6: css`
        font-size: 32px;
        line-height: 36px;
    `,
    type_7: css`
        font-size: 36px;
        line-height: 40px;
    `,
    type_8: css`
        font-size: 40px;
        line-height: 48px;
    `,
    type_9: css`
        font-size: 48px;
        line-height: 56px;
    `,
};
