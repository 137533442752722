import { css } from '@emotion/react';
import { spacing } from './spacing';
import { breakpoints } from './breakpoints';

export const grid = {
    grid_1: css`
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    `,
    grid_2: css`
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    `,
    grid_3: css`
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    `,
    grid_4: css`
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    `,
    grid_5: css`
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
    `,
    grid_6: css`
        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
    `,
    grid_7: css`
        display: grid;
        grid-template-columns: repeat(7, minmax(0, 1fr));
    `,
    grid_8: css`
        display: grid;
        grid-template-columns: repeat(8, minmax(0, 1fr));
    `,
    grid_9: css`
        display: grid;
        grid-template-columns: repeat(9, minmax(0, 1fr));
    `,
    grid_10: css`
        display: grid;
        grid-template-columns: repeat(10, minmax(0, 1fr));
    `,
    grid_11: css`
        display: grid;
        grid-template-columns: repeat(11, minmax(0, 1fr));
    `,
    grid_12: css`
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
    `,
    grid_gap_0: css`
        grid-row-gap: ${spacing.s0};
        grid-column-gap: ${spacing.s0};
    `,
    grid_gap_1: css`
        grid-row-gap: ${spacing.s1};
        grid-column-gap: ${spacing.s1};
    `,
    grid_gap_2: css`
        grid-row-gap: ${spacing.s2};
        grid-column-gap: ${spacing.s2};
    `,
    grid_gap_3: css`
        grid-row-gap: ${spacing.s3};
        grid-column-gap: ${spacing.s3};
    `,
    grid_gap_4: css`
        grid-row-gap: ${spacing.s4};
        grid-column-gap: ${spacing.s4};
    `,
    grid_gap_5: css`
        grid-row-gap: ${spacing.s5};
        grid-column-gap: ${spacing.s5};
    `,
    grid_gap_6: css`
        grid-row-gap: ${spacing.s6};
        grid-column-gap: ${spacing.s6};
    `,
    grid_gap_7: css`
        grid-row-gap: ${spacing.s7};
        grid-column-gap: ${spacing.s7};
    `,
    grid_gap_8: css`
        grid-row-gap: ${spacing.s8};
        grid-column-gap: ${spacing.s8};
    `,
    grid_gap_9: css`
        grid-row-gap: ${spacing.s9};
        grid-column-gap: ${spacing.s9};
    `,
    grid_gap_10: css`
        grid-row-gap: ${spacing.s10};
        grid-column-gap: ${spacing.s10};
    `,
    grid_gap_x0: css`
        grid-row-gap: ${spacing.s0};
    `,
    grid_gap_x1: css`
        grid-row-gap: ${spacing.s1};
    `,
    grid_gap_x2: css`
        grid-row-gap: ${spacing.s2};
    `,
    grid_gap_x3: css`
        grid-row-gap: ${spacing.s3};
    `,
    grid_gap_x4: css`
        grid-row-gap: ${spacing.s4};
    `,
    grid_gap_x5: css`
        grid-row-gap: ${spacing.s5};
    `,
    grid_gap_x6: css`
        grid-row-gap: ${spacing.s6};
    `,
    grid_gap_x7: css`
        grid-row-gap: ${spacing.s7};
    `,
    grid_gap_x8: css`
        grid-row-gap: ${spacing.s8};
    `,
    grid_gap_y0: css`
        grid-column-gap: ${spacing.s0};
    `,
    grid_gap_y1: css`
        grid-column-gap: ${spacing.s1};
    `,
    grid_gap_y2: css`
        grid-column-gap: ${spacing.s2};
    `,
    grid_gap_y3: css`
        grid-column-gap: ${spacing.s3};
    `,
    grid_gap_y4: css`
        grid-column-gap: ${spacing.s4};
    `,
    grid_gap_y5: css`
        grid-column-gap: ${spacing.s5};
    `,
    grid_gap_y6: css`
        grid-column-gap: ${spacing.s6};
    `,
    grid_gap_y7: css`
        grid-column-gap: ${spacing.s7};
    `,
    grid_gap_y8: css`
        grid-column-gap: ${spacing.s8};
    `,
};
export const breakpointGrid = {
    breakpointGrid_3: [
        grid.grid_3,
        grid.grid_gap_y5,
        breakpoints.lg(
            css`
                grid-column-gap: 32px;
            `
        ),
        breakpoints.xl(
            css`
                grid-column-gap: 40px;
            `
        ),
    ],
    breakpointGrid_4: [
        grid.grid_4,
        grid.grid_gap_y5,
        breakpoints.lg(
            css`
                grid-column-gap: 32px;
            `
        ),
        breakpoints.xl(
            css`
                grid-column-gap: 40px;
            `
        ),
    ],
    breakpointGrid_5: [
        grid.grid_5,
        grid.grid_gap_y5,
        breakpoints.lg(
            css`
                grid-column-gap: 32px;
            `
        ),
        breakpoints.xl(
            css`
                grid-column-gap: 40px;
            `
        ),
    ],
    breakpointGrid_6: [
        grid.grid_1,
        breakpoints.sm([grid.grid_6, grid.grid_gap_y5]),
        breakpoints.lg(
            css`
                grid-column-gap: 32px;
            `
        ),
        breakpoints.xl(
            css`
                grid-column-gap: 40px;
            `
        ),
    ],
    breakpointGrid_7: [
        grid.grid_7,
        grid.grid_gap_y5,
        breakpoints.lg(
            css`
                grid-column-gap: 32px;
            `
        ),
        breakpoints.xl(
            css`
                grid-column-gap: 40px;
            `
        ),
    ],
    breakpointGrid_8: [
        grid.grid_8,
        grid.grid_gap_y5,
        breakpoints.lg(
            css`
                grid-column-gap: 32px;
            `
        ),
        breakpoints.xl(
            css`
                grid-column-gap: 40px;
            `
        ),
    ],
    breakpointGrid_9: [
        grid.grid_9,
        grid.grid_gap_y5,
        breakpoints.lg(
            css`
                grid-column-gap: 32px;
            `
        ),
        breakpoints.xl(
            css`
                grid-column-gap: 40px;
            `
        ),
    ],
    breakpointGrid_10: [
        grid.grid_10,
        grid.grid_gap_y5,
        breakpoints.lg(
            css`
                grid-column-gap: 32px;
            `
        ),
        breakpoints.xl(
            css`
                grid-column-gap: 40px;
            `
        ),
    ],
    breakpointGrid_11: [
        grid.grid_1,
        breakpoints.sm([grid.grid_11, grid.grid_gap_y5]),
        breakpoints.lg(
            css`
                grid-column-gap: 32px;
            `
        ),
        breakpoints.xl(
            css`
                grid-column-gap: 40px;
            `
        ),
    ],
    breakpointGrid_12: [
        grid.grid_1,
        breakpoints.sm([grid.grid_12, grid.grid_gap_y5]),
        breakpoints.lg(
            css`
                grid-column-gap: 32px;
            `
        ),
        breakpoints.xl(
            css`
                grid-column-gap: 40px;
            `
        ),
    ],
};
