/** @jsxImportSource @emotion/react */
import React from 'react';
import t from '../../../theme/styles';
import { ProjectArticle } from '../ProjectArticle';
import { ProjectBanner } from '../ProjectBanner';
import { ProjectAside } from '../ProjectAside';
import { ProjectPage } from '../ProjectPage';
import { ProjectSectionContainer, ProjectSection, ProjectSectionHeading } from '../ProjectSection';
import { ThemeProvider } from '../../../contexts/ThemeContext';
import { ImageWithCaption } from '../../../components/imageWithCaption';
import { ImageGallery } from '../../../components/imageGallery';
import { ProjectOverview } from '../ProjectOverview';

export function ProjectProjectT() {
    return (
        <ProjectPage title="Project T">
            <ProjectBanner src="/projects/ProjectT/ProjectT_Header.jpg" alt="" />
            <ProjectArticle>
                <ProjectSectionContainer>
                    <ProjectOverview>
                        <ProjectSectionHeading label="Overview" />
                        <p>
                            Project T was a co-op shooter set in the Dead by Daylight universe built in Unreal 5. I worked as a Technical UI Designer on the "Exogame" team,
                            collaborating with UI Art, UX Design and Engineers to integrate menu flows, UI components, and HUD widgets.
                        </p>
                    </ProjectOverview>

                    <ProjectAside title="Project T" company="Midwinter Entertainment" date="2023 - 2024" titleStyle={[t.type_6]}>
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Platform</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>PC/Console</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Tools</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Unreal 5, Figma</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Skills</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>UX Design, Integration, UI Materials</div>
                        </div>
                    </ProjectAside>
                </ProjectSectionContainer>

                <ThemeProvider tint="extra-light">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="My Role" />
                            <p>
                                My primary responsibility within the team was to build interface widgets and create UI materials in Unreal's UMG and Material Editor based on the
                                needs of design or art. Eventually I also picked up some of the design work for in-game HUD elements. In addition to direct contributions to the
                                game I also applied my general game development experience to improve our team's agile process and communication with daily standups, clear goal
                                statements, and regular reviews.
                            </p>
                        </ProjectSection>
                        <ProjectSection>
                            <ProjectSectionHeading label="Challenges" />
                            <ul css={[t.list_disc, t.pl_4, t.font_body, t.type_1]}>
                                <li>Building new features and widgets on top of prototype work and custom elements rolled over from a previous project.</li>
                                <li>A development team split between two locations/timezones.</li>
                                <li>Learning Unreal UMG, Blueprint, and Material Graph in a production environment .</li>
                            </ul>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
                <ThemeProvider tint="dark">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="UMG Widgets, Test Widgets, and UI Materials" />
                            <p>
                                Most of my work was done in the Unreal Editor, where I would create UMG widgets and wire their associated data and functionality in Blueprint. In
                                many cases, the widget also required custom materials which I created in the Material Graph and worked with our UI Artist to understand which
                                elements might be best handled as parameters, for reuse or animation. We used a defined quality scale to communicate what state we were targetting
                                with each feature at any point in time, from F0 (programmer art) to F3 (shipping quality). Due to the state of development when the project was
                                cancelled, most of my work never made it past F1 quality (matching the UX but without a UI visual design pass).
                            </p>
                            <ImageGallery columns={2}>
                                <ImageWithCaption
                                    src="/projects/ProjectT/UIMaterial_Cards.png"
                                    alt="UI Material parameters for character selection cards"
                                    caption="UI Material parameters for character selection cards"
                                />
                                <ImageWithCaption src="/projects/ProjectT/UIWidgets_CharacterCards.png" alt="Character card widgets" caption="Character card widgets" />
                            </ImageGallery>
                            <p>
                                We used the MVVM plugin from Epic. I really enjoyed this explicit data contract as it provided quite a few benefits, such as a clear separation of
                                concerns, parallel starting points for feature development, and the ability for me to create test widgets. Test widgets were a way for me to use the
                                real in-game UI widgets in an isolated environment to improve my iteration speed as well as quickly confirm whether bugs were in the view side of
                                the fence or somewhere in the gameplay code.
                            </p>
                            <ImageGallery>
                                <ImageWithCaption src="/projects/ProjectT/TestWidget_Players.png" alt="" caption="Test widget for Player widgets, showing various health states" />
                            </ImageGallery>
                        </ProjectSection>
                        <ProjectSection>
                            <ProjectSectionHeading label="UX Design" />
                            <p>I designed various features for the in-game experience including the ping/blip system, compass, announcements, and the reticle/hit marker.</p>
                            <ImageGallery>
                                <ImageWithCaption src="/projects/ProjectT/ProjectT_HUD.png" alt="" caption="View of the Project T HUD" />
                            </ImageGallery>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
            </ProjectArticle>
        </ProjectPage>
    );
}
