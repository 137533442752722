/** @jsxImportSource @emotion/react */
import React from 'react';
import t from '../../theme/styles';
import { ProjectSectionContainer, ProjectSection, ProjectSectionHeading } from '../projects/ProjectSection';
import { ProjectOverview } from '../projects/ProjectOverview';
import { ProjectAside } from '../projects/ProjectAside';
import { ProjectArticle } from '../projects/ProjectArticle';
import { ProjectPage } from '../projects/ProjectPage';
import { ProjectBanner } from '../projects/ProjectBanner';
import { PortfolioReviewFooter } from './PortfolioReviewFooter';
import { ThemeProvider } from '../../contexts/ThemeContext';
import { ImageGallery } from '../../components/imageGallery';
import { ImageWithCaption } from '../../components/imageWithCaption';

export function PortfolioReviewPhotoAgent() {
    return (
        <ProjectPage>
            <ProjectBanner src="/projects/PhotoAgent/gameplay.jpg" alt="" />
            <ProjectArticle>
                <ProjectSectionContainer>
                    <ProjectOverview>
                        <ProjectSectionHeading label="Overview" />
                        <p>
                            Photo Agent was a free-to-play jigsaw puzzle game for the Facebook platform. The narrative of the game was that you're a new freelance photographer who
                            is making their name with help from your assistant, Miranda, and a host of clients, each with specific needs. With a rich set of high quality photo
                            content, the game played up the goal of traveling the world, meeting fun new people and learning about interesting new things.
                        </p>
                    </ProjectOverview>

                    <ProjectAside title="Photo Agent" company="Amazon Game Studios" date="2012">
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Platform</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Facebook</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Tools</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Flash, Actionscript, Invision, Axure</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Skills</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>UX Design, UI Design, Integration, Prototyping</div>
                        </div>
                    </ProjectAside>
                </ProjectSectionContainer>
                <ThemeProvider tint="dark">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="Gameplay" />

                            <ImageGallery>
                                <video controls muted css={[t.w_full]}>
                                    <source src="/projects/PhotoAgent/PhotoAgent_Gameplay.webm" type="video/webm" />
                                    <source src="/projects/PhotoAgent/PhotoAgent_Gameplay.mp4" type="video/mp4" />
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                            </ImageGallery>
                            <ImageGallery>
                                <ImageWithCaption src="/projects/PhotoAgent/7-Results.jpg" alt="" />
                            </ImageGallery>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
                <ThemeProvider tint="light">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="Clients" />

                            <ul>
                                <li>Categorizing and organizing a vast library of content</li>
                                <li>Lean into studio strengths</li>
                                <li>Easy to extend</li>
                            </ul>
                            <ImageGallery columns={4}>
                                <ImageWithCaption src="/projects/PhotoAgent/2-Clients.jpg" alt="Client selection menu" />
                                <ImageWithCaption src="/projects/PhotoAgent/3-MeetNGreetCropped.jpg" alt="Meeting a new client" />
                                <ImageWithCaption src="/projects/PhotoAgent/4-AllAssignments.jpg" alt="" />
                                <ImageWithCaption src="/projects/PhotoAgent/5-Details.jpg" alt="" />
                            </ImageGallery>
                            <ImageGallery>
                                <video controls muted css={[t.w_full]}>
                                    <source src="/projects/PhotoAgent/PhotoAgent_UnlockingClients.webm" type="video/webm" />
                                    <source src="/projects/PhotoAgent/PhotoAgent_UnlockingClients.mp4" type="video/mp4" />
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                            </ImageGallery>
                            <ImageGallery>
                                <ImageWithCaption src="/projects/PhotoAgent/10-Timeline.jpg" alt="" />
                            </ImageGallery>
                        </ProjectSection>
                        <ProjectSection>
                            <ProjectSectionHeading label="Economy and social features" />

                            <ImageGallery>
                                <ImageWithCaption src="/projects/PhotoAgent/MagazineBuyout.jpg" alt="" />
                            </ImageGallery>
                            <ImageGallery columns={2}>
                                <ImageWithCaption src="/projects/PhotoAgent/16-OutOfEnergy1.jpg" alt="" />
                                <ImageWithCaption src="/projects/PhotoAgent/ClientBuyout.jpg" alt="" />
                                <ImageWithCaption src="/projects/PhotoAgent/ComCenter_inbox.jpg" alt="" />
                                <ImageWithCaption src="/projects/PhotoAgent/ComCenter_News1.jpg" alt="" />
                            </ImageGallery>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
                <ThemeProvider tint="dark">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="Visual evolution" />

                            <ImageGallery columns={3}>
                                <ImageWithCaption src="/projects/PhotoAgent/fullCanvas_details_assignments.jpg" alt="" />
                                <ImageWithCaption src="/projects/PhotoAgent/11-Postcard.jpg" alt="" />
                                <ImageWithCaption src="/projects/PhotoAgent/ClientBuyout.jpg" alt="" />
                            </ImageGallery>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
            </ProjectArticle>
            <PortfolioReviewFooter />
        </ProjectPage>
    );
}
