import { css } from '@emotion/react';

export const gridAuto = {
    grid_auto_col: css`
        grid-auto-columns: minmax(0, 1fr);
    `,
    grid_auto_row: css`
        grid-auto-rows: minmax(0, 1fr);
    `,
    grid_autoFlow_col: css`
        grid-auto-flow: column;
    `,
    grid_autoFlow_row: css`
        grid-auto-flow: row;
    `,
};
