/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { ThemeProvider } from '../../contexts/ThemeContext';
import { ProjectData } from '../../data/ProjectData';
import t, { CSSStyle } from '../../theme/styles';
import { ProjectSectionContainer } from './ProjectSection';
import { motion, Variants } from 'framer-motion';

export function ProjectArticle(props: { children?: ReactNode }) {
    return (
        <article css={[t.flex, t.flex_col]}>
            {props.children}
            <ProjectNavBar />
        </article>
    );
}

function ProjectNavBar() {
    let { path } = useRouteMatch();
    const projectName = path.slice(10, -1); // slice off /projects/ and trailing /

    const project = ProjectData[projectName];
    const projectsArray = Object.keys(ProjectData);

    if (!project) return null;

    const projectIndex = projectsArray.indexOf(projectName);

    const prevProjectName = projectsArray[projectIndex - 1];
    const prevProject = ProjectData[prevProjectName];

    const nextProjectName = projectsArray[projectIndex + 1];
    const nextProject = ProjectData[nextProjectName];

    return (
        <ThemeProvider tint="extra-dark">
            <ProjectSectionContainer style={[t.border_t, t.border_solid, t.border_dark_2]}>
                <nav css={[t.pt_7, t.flex, t.text_primary_1]}>
                    {prevProjectName ? <NavBarLink to={`/projects/${prevProjectName}`} label={`${prevProject.Name}`} direction="previous" /> : null}
                    <div css={[t.flex_auto]} />
                    {nextProjectName ? <NavBarLink to={`/projects/${nextProjectName}`} label={`${nextProject.Name}`} direction="next" /> : null}
                </nav>
            </ProjectSectionContainer>
        </ThemeProvider>
    );
}

const linkVariants: Variants = {
    hover: { scale: 1.02 },
    unhover: { scale: 1 },
    focus: {},
    blur: {},
};

const chevronVariants: Variants = {
    hover: (direction: NavDirection) => ({ x: direction === 'next' ? 8 : -8, scale: 1.02 }),
    unhover: { x: 0, scale: 1 },
    focus: {},
    blur: {},
};

type NavDirection = 'next' | 'previous';

function NavBarLink(props: { to: string; label: string; direction: NavDirection }) {
    const [focus, SetFocus] = useState(false);
    const [hover, SetHover] = useState(false);

    const chevronStyle: CSSStyle = [t.bg_primary_1, t.w('3px'), t.h('18px')];
    return (
        <Link
            to={props.to}
            css={[t.flex_none, t.flex, t.items_center, t.text_inherit, t.no_underline, t.visited([t.text_inherit])]}
            onFocus={() => SetFocus(true)}
            onBlur={() => SetFocus(false)}
            onMouseEnter={() => SetHover(true)}
            onMouseLeave={() => SetHover(false)}
        >
            <motion.div css={[t.flex]} initial={['unhover', 'blur']} animate={[hover ? 'hover' : 'unhover', focus ? 'focus' : 'blur']} variants={linkVariants}>
                {props.direction === 'previous' ? (
                    <motion.div css={[t.flex, t.flex_col, t.pr_5, t.pl_4]} custom={props.direction} variants={chevronVariants}>
                        <div css={[chevronStyle, t.skew_X(-30)]} />
                        <div css={[chevronStyle, t.skew_X(30)]} />
                    </motion.div>
                ) : null}
                <div css={[t.py_2, t.type_2]}>{props.label}</div>
                {props.direction === 'next' ? (
                    <motion.div css={[t.flex, t.flex_col, t.pl_5, t.pr_4]} custom={props.direction} variants={chevronVariants}>
                        <div css={[chevronStyle, t.skew_X(30)]} />
                        <div css={[chevronStyle, t.skew_X(-30)]} />
                    </motion.div>
                ) : null}
            </motion.div>
        </Link>
    );
}
