/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef } from 'react';
import { Global } from '@emotion/react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import t from './theme/styles';
import 'react-medium-image-zoom/dist/styles.css';
import { InitializeFirebase } from './Firebase';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Homepage } from './pages/homepage/Homepage';
import { Footer } from './components/footer';
import { Projects } from './pages/projects/Projects';
import { Header } from './components/header';
import { Project1vs100 } from './pages/projects/projectPages/Project1vs100';
import { ProjectUnmaking } from './pages/projects/projectPages/ProjectUnmaking';
import { ProjectClients } from './pages/projects/projectPages/ProjectClients';
import { ProjectPhotoAgent } from './pages/projects/projectPages/ProjectPhotoAgent';
import { ProjectTowerRaider2 } from './pages/projects/projectPages/ProjectTowerRaiders2';
import { ProjectXbox } from './pages/projects/projectPages/ProjectXbox';
import { ProjectH2V } from './pages/projects/projectPages/ProjectH2V';
import { ProjectPopcap } from './pages/projects/projectPages/ProjectPopcap';
import { ProjectDynamicWall } from './pages/projects/projectPages/ProjectDynamicWall';
import { ProjectPersonalArt } from './pages/projects/projectPages/ProjectPersonalArt';
import { ProjectRobotCanyon } from './pages/projects/projectPages/ProjectRobotCanyon';
import { PortfolioReviewDynamicWall } from './pages/portfolioReview/PortfolioReviewDynamicWall';
import { PortfolioReviewIntro } from './pages/portfolioReview/PortfolioReviewIntro';
import { PortfolioReviewPopcap } from './pages/portfolioReview/PortfolioReviewPopcap';
import { PortfolioReviewPhotoAgent } from './pages/portfolioReview/PortfolioReviewPhotoAgent';
import { ProjectProjectT } from './pages/projects/projectPages/ProjectProjectT';

InitializeFirebase();

function App() {
    return (
        <React.Fragment>
            <Router>
                <HelmetProvider>
                    <Helmet>
                        <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet" />
                        <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@200;400;700&display=swap" rel="stylesheet" />
                    </Helmet>
                    <Global styles={t.Global} />
                    <Switch>
                        <Route exact path="/" component={Homepage} />
                        <Route path="/" component={PageRouter} />
                    </Switch>
                </HelmetProvider>
            </Router>
        </React.Fragment>
    );
}

function PageRouter() {
    const history = useHistory();
    const pageRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        return history.listen(() => {
            pageRef.current?.scrollTo(0, 0);
        });
    }, [history, pageRef]);

    return (
        <React.Fragment>
            <Header />
            <div css={[t.fixed, t.inset_0, t.overflow_hidden, t.pt('52px')]}>
                <div ref={pageRef} css={[t.h_full, t.overflow_x_hidden, t.overflow_y_auto, t.flex, t.flex_col]}>
                    <Switch>
                        <Route exact path="/portfolio-review" component={PortfolioReviewIntro} />
                        <Route path="/portfolio-review/" component={PortfolioReviewRouter} />
                        <Route exact path="/projects" component={Projects} />
                        <Route path="/projects/" component={ProjectRouter} />
                    </Switch>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    );
}

function ProjectRouter() {
    return (
        <React.Fragment>
            <Switch>
                <Route path="/projects/project-T/" component={ProjectProjectT} />
                <Route path="/projects/1-vs-100/" component={Project1vs100} />
                <Route path="/projects/the-unmaking/" component={ProjectUnmaking} />
                <Route path="/projects/client-projects/" component={ProjectClients} />
                <Route path="/projects/photo-agent/" component={ProjectPhotoAgent} />
                <Route path="/projects/tower-raider-2/" component={ProjectTowerRaider2} />
                <Route path="/projects/xbox/" component={ProjectXbox} />
                <Route path="/projects/halo-2-vista/" component={ProjectH2V} />
                <Route path="/projects/popcap-projects/" component={ProjectPopcap} />
                <Route path="/projects/dynamic-wall/" component={ProjectDynamicWall} />
                <Route path="/projects/personal-artwork/" component={ProjectPersonalArt} />
                <Route path="/projects/robot-canyon/" component={ProjectRobotCanyon} />
            </Switch>
        </React.Fragment>
    );
}

function PortfolioReviewRouter() {
    return (
        <React.Fragment>
            <Switch>
                <Route path="/portfolio-review/photo-agent/" component={PortfolioReviewPhotoAgent} />
                <Route path="/portfolio-review/popcap-projects/" component={PortfolioReviewPopcap} />
                <Route path="/portfolio-review/dynamic-wall/" component={PortfolioReviewDynamicWall} />
            </Switch>
        </React.Fragment>
    );
}

export default App;
