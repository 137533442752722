/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import t, { CSSStyle } from '../../theme/styles';
import { useThemeController } from '../../contexts/ThemeContext';
import { Page } from '../../components/page';
import { motion, Variants } from 'framer-motion';

const containerVariants: Variants = {
    default: { y: -10, opacity: 0 },
    reveal: { y: 0, opacity: 1, transition: { ease: 'easeInOut', duration: 1.4, delay: 0.4 } },
};

export function ProjectSectionContainer(props: { children?: ReactNode; style?: CSSStyle }) {
    const theme = useThemeController();
    let bgTint = t.bg_tint_1;
    let textTint = t.text_dark_5;

    if (theme.tint) {
        switch (theme.tint) {
            case 'extra-light':
                bgTint = t.bg_tint_3;
                textTint = t.text_dark_4;
                break;
            case 'light':
                bgTint = t.bg_tint_4;
                textTint = t.text_dark_3;
                break;
            case 'dark':
                bgTint = t.bg_dark_4;
                textTint = t.text_tint_3;
                break;
            case 'extra-dark':
                bgTint = t.bg_dark_3;
                textTint = t.text_tint_2;
                break;
            case 'primary':
                bgTint = t.bg_primary_1;
                textTint = t.text_tint_1;
                break;
        }
    }

    return (
        <div css={[t.flex_auto, t.flex, bgTint, textTint, t.type_1, t.font_body, props.style]}>
            <Page style={[t.flex_auto, t.flex, t.flex_col, t.relative, t.pb_8]}>{props.children}</Page>
        </div>
    );
}

export function ProjectSection(props: { style?: CSSStyle; children?: ReactNode }) {
    return (
        <motion.div css={[t.flex_auto, t.pt_7, t.flex, t.flex_col, props.style]} initial="default" animate="reveal" variants={containerVariants}>
            <section
                css={[
                    t.flex,
                    t.flex_col,
                    css`
                        p {
                            margin-bottom: 16px;
                        }
                        li {
                            margin-bottom: 10px;
                        }
                    `,
                ]}
            >
                {props.children}
            </section>
        </motion.div>
    );
}

export function ProjectSectionHeading(props: { label: string; style?: CSSStyle }) {
    return <h2 css={[t.font_heading, t.font_normal, t.type_5, t.mb_4, props.style]}>{props.label}</h2>;
}
