/** @jsxImportSource @emotion/react */
import React from 'react';
import { ImageGallery } from '../../../components/imageGallery';
import { ImageWithCaption } from '../../../components/imageWithCaption';
import { ThemeProvider } from '../../../contexts/ThemeContext';
import { ProjectArticle } from '../ProjectArticle';
import { ProjectBanner } from '../ProjectBanner';
import { ProjectPage } from '../ProjectPage';
import { ProjectSection, ProjectSectionContainer, ProjectSectionHeading } from '../ProjectSection';

export function ProjectPersonalArt() {
    return (
        <ProjectPage title="Personal Artwork">
            <ProjectBanner src="/projects/Artwork/towerhead_header.jpg" alt="" />
            <ProjectArticle>
                <ThemeProvider tint="white">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="Overview" />
                            <p>
                                I love to draw. Often creatures. Okay, almost always creatures. I like working with ink and watercolor, but I'm still very much in the learning
                                phase with these materials. I'll keep posting my stuff here. If you want me to make you something or want a copy of a thing I've done, just let me
                                know.
                            </p>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>

                <ThemeProvider tint="extra-light">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="Gifts" />
                            <p>
                                I struggle a lot with writing. It rarely ever feels natural and never happens quickly. Instead of spending my time trying to think of something to
                                say on a holiday or birthday card, now I just draw.
                            </p>
                            <ImageGallery>
                                <ImageWithCaption src="/projects/Artwork/StumpSalesman.jpg" alt="Stump Salesman" />
                            </ImageGallery>
                            <ImageGallery columns={2}>
                                <ImageWithCaption src="/projects/Artwork/Robobuddies.jpg" alt="Robo Buddies" />
                                <ImageWithCaption src="/projects/Artwork/ExerciseBot.jpg" alt="Motivational Bots" />
                            </ImageGallery>
                            <ImageGallery columns={3}>
                                <ImageWithCaption src="/projects/Artwork/AeroDJ.jpg" alt="AeroDJ" />
                                <ImageWithCaption src="/projects/Artwork/TheLibrarian.jpg" alt="Jasmine's Librarian" />
                                <ImageWithCaption src="/projects/Artwork/CakeMountain.jpg" alt="Cake Mountain" />
                            </ImageGallery>
                            <ImageGallery columns={2}>
                                <ImageWithCaption src="/projects/Artwork/RoadTrip.jpg" alt="Road Trip" />
                                <ImageWithCaption src="/projects/Artwork/AutomaticGardner.jpg" alt="Automatic Gardner" />
                            </ImageGallery>
                            <ImageGallery>
                                <ImageWithCaption src="/projects/Artwork/Burl.jpg" alt="Burl - Master Carpenter" />
                            </ImageGallery>
                            <ImageGallery columns={2}>
                                <ImageWithCaption src="/projects/Artwork/ThePoet.jpg" alt="The Poet" />
                                <ImageWithCaption src="/projects/Artwork/TheBarista.jpg" alt="The Barista" />
                            </ImageGallery>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>

                <ThemeProvider tint="light">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="Color blob creatures" />
                            <p>
                                I started doing these while I was making our wedding invitations. I just lay down some watercolor that I'm using and try not too think too much
                                about it, later when it's dry I see if I can find a creature hiding in there somewhere.
                            </p>
                            <ImageGallery columns={4}>
                                <ImageWithCaption src="/projects/Artwork/creature1.jpg" alt="Creature 01" />
                                <ImageWithCaption src="/projects/Artwork/creature2.jpg" alt="Creature 02" />
                                <ImageWithCaption src="/projects/Artwork/creature3.jpg" alt="Creature 03" />
                                <ImageWithCaption src="/projects/Artwork/creature4.jpg" alt="Creature 04" />
                                <ImageWithCaption src="/projects/Artwork/creature5.jpg" alt="Creature 05" />
                                <ImageWithCaption src="/projects/Artwork/creature6.jpg" alt="Creature 06" />
                                <ImageWithCaption src="/projects/Artwork/creature7.jpg" alt="Creature 07" />
                                <ImageWithCaption src="/projects/Artwork/creature8.jpg" alt="Creature 08" />
                            </ImageGallery>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>

                <ThemeProvider tint="light">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="Towerhead" />
                            <p>
                                My first digital painting. I did this for some friends as a wedding gift. I seriously cannot remember why I went with a guy who has a community of
                                tree-dwellers fixed to his head. No clue.
                            </p>
                            <ImageGallery>
                                <ImageWithCaption src="/projects/Artwork/towerhead_final.jpg" alt="Towerhead" />
                            </ImageGallery>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
            </ProjectArticle>
        </ProjectPage>
    );
}
