/** @jsxImportSource @emotion/react */
import React from 'react';
import t from '../../theme/styles';

import { ProjectSectionContainer, ProjectSection } from '../projects/ProjectSection';
import { ThemeProvider } from '../../contexts/ThemeContext';
import { TimelineSpine } from '../../components/Timeline/TimelineSpine';
import { TimelineEvent } from '../../components/Timeline/TimelineEvent';
import { JobTitleListItem } from '../../components/Timeline/JobTitleListItem';
import { ProjectData } from '../../data/ProjectData';
import { ProjectCard } from '../projects/ProjectCard';
import { ProjectArticle } from '../projects/ProjectArticle';
import { ProjectPage } from '../projects/ProjectPage';
import { PortfolioReviewFooter } from './PortfolioReviewFooter';

export function PortfolioReviewIntro() {
    return (
        <ProjectPage title="Portfolio Review">
            <ProjectArticle>
                <ThemeProvider tint="extra-dark">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <div css={[t.flex, t.flex_col, t.justify_center, t.items_center, t.h('70vh')]}>
                                <h1 css={[t.type_9, t.font_normal, t.tracking_wider]}>PORTFOLIO REVIEW</h1>
                                <p css={[t.mt_2, t.text_primary_1, t.type_4, t.font_extra_light, t.tracking_wider]}>APR 6 2021</p>
                            </div>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
                <ThemeProvider tint="primary">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <div css={[t.flex, t.flex_col, t.justify_center, t.items_center, t.h('40vh')]}>
                                <p css={[t.flex, t.type_9, t.font_bold, t.tracking_wider]}>Hi!</p>
                                <p css={[t.flex, t.type_8, t.font_bold, t.tracking_wider]}>I'm Colin!</p>
                            </div>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
                <PorfolioReviewTimeline />
                <PortfolioReviewGallery />
            </ProjectArticle>
            <PortfolioReviewFooter />
        </ProjectPage>
    );
}

function PorfolioReviewTimeline() {
    return (
        <ThemeProvider tint="light">
            <ProjectSectionContainer>
                <ProjectSection>
                    <div css={[t.relative, t.mt_8]}>
                        <TimelineSpine />
                        <div css={[t.flex, t.flex_col]}>
                            <TimelineEvent side="right" date="1999 - 2004" location="University of Nebraska - Lincoln" locationStyle={[t.font_bold]}>
                                <ul>
                                    <JobTitleListItem title="B.S. Computer Engineering" />
                                </ul>
                            </TimelineEvent>
                            <TimelineEvent side="right" date="2006 - 2012" location="Microsoft" locationStyle={[t.font_bold]}></TimelineEvent>
                            <TimelineEvent side="right" date="2012 - 2013" location="Amazon Game Studios" height={32} locationStyle={[t.font_bold]}></TimelineEvent>
                            <TimelineEvent side="right" date="2013 - 2014" location="Intentional Futures" locationStyle={[t.font_bold]}></TimelineEvent>
                            <TimelineEvent side="right" date="2014 - 2015" location="Amazon Game Studios" locationStyle={[t.font_bold]}></TimelineEvent>
                            <TimelineEvent side="right" date="2015 - 2019" location="EA - PopCap" locationStyle={[t.font_bold]}></TimelineEvent>
                        </div>
                    </div>
                    <TimelineEvent firstEntry side="right" date="2019 -" location="Pilot Labs" locationStyle={[t.font_bold]}></TimelineEvent>
                </ProjectSection>
            </ProjectSectionContainer>
        </ThemeProvider>
    );
}

const orderedProjects = ['dynamic-wall', 'popcap-projects', 'the-unmaking', 'client-projects', 'photo-agent', 'xbox', '1-vs-100', 'halo-2-vista'];

function PortfolioReviewGallery() {
    return (
        <ThemeProvider tint="light">
            <ProjectSectionContainer>
                <ProjectSection>
                    <div
                        css={[
                            t.flex_auto,
                            t.pt_2,
                            t.pb_9,
                            t.grid_gap_6,
                            t.grid_1,
                            t.sm([t.grid_2]),
                            t.md([t.grid_gap_7]),
                            t.lg([t.grid_4, t.grid_gap_y5]),
                            t.xl([t.grid_gap_x7, t.grid_gap_y6]),
                        ]}
                    >
                        {orderedProjects.map((projectKey: string, index: number) => {
                            if (index >= 8) return null;
                            const project = ProjectData[projectKey];
                            return <ProjectCard key={projectKey} index={index} label={project.Name} to={`/projects/${projectKey}`} src={project.Src} alt={project.Alt} />;
                        })}
                    </div>
                </ProjectSection>
            </ProjectSectionContainer>
        </ThemeProvider>
    );
}
