/** @jsxImportSource @emotion/react */
import React from 'react';
import t from '../../../theme/styles';
import { ProjectArticle } from '../ProjectArticle';
import { ProjectBanner } from '../ProjectBanner';
import { ProjectAside } from '../ProjectAside';
import { ProjectPage } from '../ProjectPage';
import { ProjectSectionContainer, ProjectSection, ProjectSectionHeading } from '../ProjectSection';
import { ThemeProvider } from '../../../contexts/ThemeContext';
import { ImageWithCaption } from '../../../components/imageWithCaption';
import { ImageGallery } from '../../../components/imageGallery';
import { ProjectOverview } from '../ProjectOverview';
import { BrowserRouter as Router, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ProjectSubPage } from '../ProjectSubPage';

export function Project1vs100() {
    let { path } = useRouteMatch();

    return (
        <ProjectPage title="1 vs. 100">
            <ProjectBanner src="/projects/1vs100/1vs100_Header.jpg" alt="" />
            <ProjectArticle>
                <ProjectSectionContainer>
                    <ProjectOverview>
                        <ProjectSectionHeading label="Overview" />
                        <p>
                            1 vs. 100 was a massively multiplayer online trivia game show on Xbox Live. It was an adaptation of the tv game show of the same name. It ran for two
                            seasons (which essentially mapped to two development cycles) and was cancelled while we were planning the third season. There were two modes of play,
                            Live and Extended Play, but always ran as a single session game, if you were playing you were playing with everyone.
                        </p>
                        <p>The Live episodes featured a live host, custom relevant questions, real prizes, and more closely matched the tv show’s format.</p>
                        <p>
                            Extended Play was usually themed and ran more regularly during each week. The rules were modified so that scoring was proportional to how many players
                            missed a question.
                        </p>
                    </ProjectOverview>

                    <ProjectAside title="1 vs. 100" company="Microsoft" date="2009">
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Platform</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Xbox 360</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Tools</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Rare's R1 Engine, Maya, XUI, Lua</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Skills</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>UX Design, Game Design, UI Design, Integration, Technical Art, Tool Development, Prototyping</div>
                        </div>
                    </ProjectAside>
                </ProjectSectionContainer>

                <Router>
                    <Switch>
                        <Route exact path={path} component={Article} />
                        <Route exact path={`${path}gallery`}>
                            <Gallery projectPath={path} />
                        </Route>
                    </Switch>
                </Router>
            </ProjectArticle>
        </ProjectPage>
    );
}

function Article() {
    return (
        <React.Fragment>
            <ThemeProvider tint="white">
                <ProjectSectionContainer>
                    <ProjectSection style={[t.pt_0]}>
                        <ProjectSectionHeading label="Live Episodes" />
                        <p>
                            This game is truly one-of-a-kind and still is. It also takes so many words trying to explain it, so here is a video of someone playing during a LIVE
                            episode in our second season.
                        </p>
                        <ImageGallery>
                            <div css={[t.relative, t.w_full]}>
                                <img
                                    css={[t.w_full, t.h_auto]}
                                    src={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9'%3E%3C/svg%3E`}
                                    alt="aspect ratio placeholder"
                                    width={16}
                                    height={9}
                                />
                                <div css={[t.absolute, t.inset_0]}>
                                    <iframe
                                        title="Video of someone playing 1 vs. 100 on Xbox LIVE"
                                        src="https://www.youtube.com/embed/e4I6MZN9U3I"
                                        allow="picture-in-picture"
                                        allowFullScreen
                                        width="100%"
                                        height="100%"
                                    ></iframe>
                                </div>
                            </div>
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
            <ThemeProvider tint="extra-light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="My Role" />
                        <p>
                            Lead UI design and helped to define the look and feel of the game. Integrated and polished all art assets. Helped to design a unique gaming experience
                            from concept to completion. Co-developed a solution that allows for designers to tweak/polish all in-game events. Supported artists with custom tools,
                            mentorship and critique. Delivered video capture, screenshots and web assets for marketing.
                        </p>
                    </ProjectSection>
                    <ProjectSection>
                        <ProjectSectionHeading label="Challenges" />
                        <ul css={[t.list_disc, t.pl_4, t.font_body, t.type_1]}>
                            <li>One session was a huge technical and design challenge</li>
                            <li>Incorporating and designing around a live host</li>
                            <li>Communicating game state changes and roles to all players</li>
                            <li>Tuning pace when one player has control</li>
                            <li>Incorporating branding and ads without violating player trust</li>
                        </ul>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>

            <ThemeProvider tint="dark">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="Design & Development" />
                        <p>
                            I would describe the development of this game as one big continuous prototype. We were constantly reacting to adjusted technical constraints around the
                            server round trip time. We started by prototyping the game’s core loop and timing with clips from the tv show and later replacing those with renders
                            from Maya. With no other game experiences to compare to, almost every challenge required design adjustments and prototyping.
                        </p>
                        <ImageGallery columns={2}>
                            <ImageWithCaption src="/projects/1vs100/1vs100_MobRender.jpg" alt="Early render of the Mob" caption="Early render of the Mob" />
                            <ImageWithCaption src="/projects/1vs100/One_wins.jpg" alt="Early render of the The One’s podium" caption="Early render of the The One’s podium" />
                        </ImageGallery>
                    </ProjectSection>
                    <ProjectSection>
                        <ProjectSectionHeading label="Interface & Player Roles" />
                        <p>
                            With multiple player roles and specifically one critical role that very few people would ever experience it was important to design a system for
                            organizing gameplay information and feedback. I isolated local player feedback to the upper part of the screen as 2D widgets. Game state was
                            communicated through the set and The One had a dedicated jumbotron to show everyone playing their state. On some occasions the lower part of the 2D
                            space was used to re-enforce changes in the game state. The Question/Answer interface was one of the few times when the 3D set wasn’t in view, as a
                            signal that it’s time for everyone to do something again.
                        </p>
                        <ImageGallery>
                            <ImageWithCaption src="/projects/1vs100/EN_QAUI02.jpg" alt="QA screen and player widgets" caption="QA screen and player widgets" />
                            <div>
                                <video controls css={[t.w_full]}>
                                    <source src="/projects/1vs100/1vs100_EP_QA.webm" type="video/webm" />
                                    <source src="/projects/1vs100/1vs100_EP_QA.mp4" type="video/mp4" />
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                                <span css={[t.type_0]}>Video of the QA loop (from Extended Play)</span>
                            </div>
                        </ImageGallery>

                        <ImageGallery columns={3} style={[t.mt_5]}>
                            <ImageWithCaption src="/projects/1vs100/EN_MobElim1.jpg" alt="Mob Elimination Sequence" caption="Mob Elimination Sequence" />
                            <ImageWithCaption src="/projects/1vs100/EN_MobWins4.jpg" alt="Player widget trays and roles" caption="Player widget trays and roles" />
                            <ImageWithCaption src="/projects/1vs100/EN_QAReveal01.jpg" alt="The One’s answer revealed in set" caption="The One’s answer revealed in set" />
                        </ImageGallery>
                    </ProjectSection>
                    <ProjectSection>
                        <ProjectSectionHeading label="Cameras & Set Feedback" />
                        <p>
                            Camera angles/movement, lighting, and set design/feedback were some of the most critical systems to achieving our creative goals. I helped architect a
                            system for syncing and sequencing these systems with game state changes from the server. With this system we crafted shots that balanced providing
                            critical gameplay information, with local player action (like dancing in the background), and dramatic moods for The One and Mob relationship.
                        </p>
                        <ImageGallery columns={2}>
                            <ImageWithCaption src="/projects/1vs100/EN_1vs100OneWins_02.jpg" alt="The One Wins" caption="The One Wins" />
                            <ImageWithCaption src="/projects/1vs100/EN_1vs100Host_01.jpg" alt="Round Intro with Live Host" caption="Round Intro with Live Host" />
                            <ImageWithCaption src="/projects/1vs100/EN_OneIntro_01.jpg" alt="The One’s Intro" caption="The One’s Intro" />
                            <ImageWithCaption src="/projects/1vs100/One_021.jpg" alt="The Money or Mob decision" caption="The Money or Mob decision" />
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>

            <ThemeProvider tint="extra-light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="Season 2" />
                        <p>
                            With Season 2 we had a chance to step back, observe the game in the wild and talk more deeply about the design. We focused on crafting a more immersive
                            FTUE for all players, giving The One a chance to show off in their intro, a conclusion to each Live round that rewarded everyone’s play, and we
                            introduced earned Titles and Dances for progression and expression. Also, with Extended Play we provided more opportunities to play and used thematic
                            episodes to drive community engagement.
                        </p>
                        <ImageGallery>
                            <ImageWithCaption src="/projects/1vs100/1vs100_Season2_Exterior.jpg" alt="Updated game intro sequence" caption="Updated game intro sequence" />
                        </ImageGallery>
                    </ProjectSection>
                    <ProjectSection>
                        <ProjectSectionHeading label="UX Improvements" />
                        <p>
                            One of the top issues identified in our user research was confusion about which role (The One, the mob, or the crowd) a player was assigned. We
                            addressed this issue with multiple solutions:
                        </p>
                        <ul css={[t.list_disc, t.pl_4, t.font_body, t.type_1]}>
                            <li>Emphasizing The One as a clearly unique role</li>
                            <li>
                                Redesigning the crowd's section within the arena (where most players would be) to look more ... crowd-like and less like the Mob's screen filled
                                area
                            </li>
                            <li>Updated the visual states for the "player widgets" and added timing that was coordinated with the introduction of each role.</li>
                            <li>New live stat displays during intros, and during show breaks that called attention to the different roles</li>
                        </ul>
                        <ImageGallery columns={2}>
                            <ImageWithCaption src="/projects/1vs100/1vs100_Season2_OneIntro3.jpg" alt="Enhanced One Intro" caption="Enhanced One Intro" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_Season2_CrowdIntro.jpg" alt="Redesigned crowd section" caption="Redesigned crowd section" />
                        </ImageGallery>
                        <ImageGallery>
                            <div>
                                <video controls css={[t.w_full]}>
                                    <source src="/projects/1vs100/1vs100_PT_Intro.webm" type="video/webm" />
                                    <source src="/projects/1vs100/1vs100_PT_Intro.mp4" type="video/mp4" />
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                                <span css={[t.type_0]}>Season 2's Live episode intro (apologies for the creepy counting audio, this capture is from internal testing)</span>
                            </div>
                        </ImageGallery>
                        <p css={[t.mt_5]}>
                            A key point in the game that we just forgot to pay any attention to during our rushed Season 1 development, the end of a round. In Season 1, a round
                            would end whenever The One won, cashed out, or lost and we would just pick a new player and restart the round, not really giving most of our players any
                            closure about their own performances. In Season 2 we added a round summary sequence (entirely set in a UI space so as not to confuse with any events
                            within the show), that showed who performed best within your cohort and called out notable events. We also added unlockable dances and status-based
                            labels which were rewarded during this sequence.
                        </p>
                        <ImageGallery>
                            <div>
                                <video controls css={[t.w_full]}>
                                    <source src="/projects/1vs100/1vs100_PT_RoundSummary.webm" type="video/webm" />
                                    <source src="/projects/1vs100/1vs100_PT_RoundSummary.mp4" type="video/mp4" />
                                    Sorry, your browser doesn't support embedded videos.
                                </video>
                                <span css={[t.type_0]}>Season 2's round summary (again, apologies for the counting audio, this capture is from internal testing)</span>
                            </div>
                        </ImageGallery>
                    </ProjectSection>
                    <ProjectSection>
                        <ProjectSectionHeading label="Themed Episodes" />
                        <p>
                            I developed and implemented a way of loading optional assets, triggered by a string that the live operations team would embed into their scheduling
                            tools. This allowed us to plan our content needs in advance, release well in advance through background updates, and then magically turning on for our
                            Primetime Live episodes.
                        </p>
                        <ImageGallery columns={4}>
                            <ImageWithCaption src="/projects/1vs100/1vs100_Holiday_HostBooth.jpg" alt="Live Host with Holiday theming" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_Holiday_Set.jpg" alt="1 vs. 100 Arena with Holiday theming" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_NewYears_HostBooth.jpg" alt="Live Host with New Year's theming" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_NewYears_Set.jpg" alt="1 vs. 100 Arena with New Year's theming" />
                        </ImageGallery>
                    </ProjectSection>
                    <ProjectSection>
                        <ProjectSectionHeading label="Extended Play" />
                        <p>
                            We added a new gameplay mode in Season 2 that filled in the gaps between our live shows. The design premise for this mode was each player was The One,
                            and every other player, relative to them, was the Mob. A key element for making this new mode work was a scoring model that rewarded points based on how
                            many other people got a question wrong. Get an easy question right and you probably don't get many points, get a hard question right and you cash in.
                            This mode didn't require a live host, or selecting The One, or waiting on The One's decisions which allowed us to increase the overall pace and run our
                            game more frequently.
                        </p>
                        <p>
                            To keep Extended Play interesting, we made the questions grouped thematically allowing domain area specialists to thrive. Our video game trivia episodes
                            quickly became our most popular.
                        </p>
                        <ImageGallery columns={3}>
                            <ImageWithCaption src="/projects/1vs100/1vs100_Season2_EPTheme1.jpg" alt="1 vs. 100 Extended Play Video Game theme" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_Season2_EPTheme2.jpg" alt="1 vs. 100 Extended Play Movies theme" />
                            <ImageWithCaption src="/projects/1vs100/1v_Ext.jpg" alt="1 vs. 100 Extended Play Arena" />
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>

            <ThemeProvider tint="primary">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="Player Feedback" style={[t.text_tint_1]} />

                        <a href="http://www.polygon.com/2014/1/27/5350684/microsoft-something-like-1-vs-100-coming-to-xbox-one" css={[t.text_tint_1, t.mb_3]}>
                            Polygon, Jan 27, 2014
                        </a>
                        <ul css={[t.list_disc, t.pl_4, t.font_body, t.type_2]}>
                            <li>
                                “…almost literally <b>my favorite game</b> on my 360. I fumed when MS took it away.”
                            </li>
                            <li>
                                “…damn near the coolest thing on a console this generation. It felt like I was part of <b>something momentous</b> in gaming.”
                            </li>
                            <li>
                                “I remember when my whole family was in the living room, and my young son got selected as the One. We were all cheering him on like crazy for 20
                                minutes, it was <b>such a fucking blast</b>.”
                            </li>
                            <li>“Man 1 vs. 100 was something I actually could play with my whole family.”</li>
                            <li>
                                “1 vs. 100 was <b>amazing</b>. My wife and I would run two 360s but sit in the same room. We’d also have her Dad and brother join in over Live. It
                                was just a good time.”
                            </li>
                        </ul>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
        </React.Fragment>
    );
}

function Gallery(props: { projectPath: string }) {
    return (
        <ProjectSubPage projectPath={props.projectPath}>
            <ThemeProvider tint="light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ImageGallery columns={4}>
                            <ImageWithCaption src="/projects/1vs100/One_01.jpg" alt="The One" caption="The One" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_MobRender.jpg" alt="Early render of the Mob" caption="Early render of the Mob" />
                            <ImageWithCaption src="/projects/1vs100/One_wins.jpg" alt="Early render of the The One’s podium" caption="Early render of the The One’s podium" />
                            <ImageWithCaption src="/projects/1vs100/EN_QAUI02.jpg" alt="QA screen and player widgets" caption="QA screen and player widgets" />
                            <ImageWithCaption src="/projects/1vs100/EN_MobElim1.jpg" alt="Mob Elimination Sequence" caption="Mob Elimination Sequence" />
                            <ImageWithCaption src="/projects/1vs100/EN_MobWins4.jpg" alt="Player widget trays and roles" caption="Player widget trays and roles" />
                            <ImageWithCaption src="/projects/1vs100/EN_QAReveal01.jpg" alt="The One’s answer revealed in set" caption="The One’s answer revealed in set" />
                            <ImageWithCaption src="/projects/1vs100/EN_1vs100OneWins_02.jpg" alt="The One Wins" caption="The One Wins" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_OneWinsGold.jpg" alt="The One Wins" caption="The One Wins" />
                            <ImageWithCaption src="/projects/1vs100/EN_1vs100Host_01.jpg" alt="Round Intro with Live Host" caption="Round Intro with Live Host" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_HostsPIP.jpg" alt="Live Host PIP handoff" caption="Live Host PIP handoff" />
                            <ImageWithCaption src="/projects/1vs100/EN_OneIntro_01.jpg" alt="The One’s Intro" caption="The One’s Intro" />
                            <ImageWithCaption src="/projects/1vs100/One_021.jpg" alt="The Money or Mob decision" caption="The Money or Mob decision" />
                        </ImageGallery>
                        <ImageGallery>
                            <ImageWithCaption src="/projects/1vs100/1vs100_dew_ad3_fullbranding.jpg" alt="Full branding" caption="Full branding" />
                            <ImageWithCaption src="/projects/1vs100/1vs100Theme_template.jpg" alt="Xbox Dashboard theme" caption="Xbox Dashboard theme" />
                            <ImageWithCaption src="/projects/1vs100/1vs100BDE_background.jpg" alt="Xbox Dashboard 'BDE' Background" caption="Xbox Dashboard 'BDE' Background" />
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
            <ThemeProvider tint="extra-light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="Season 2" />

                        <ImageGallery columns={4}>
                            <ImageWithCaption src="/projects/1vs100/1vs100_Season2_Exterior.jpg" alt="Updated game intro sequence" caption="Updated game intro sequence" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_Season2_LobbyDancing3.jpg" alt="Updated lobby experience" caption="Updated lobby experience" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_Season2_OneIntro3.jpg" alt="Enhanced One Intro" caption="Enhanced One Intro" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_Season2_OneIntro5.jpg" alt="Enhanced One Intro" caption="Enhanced One Intro" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_Season2_OneScreen.jpg" alt="Updated game intro sequence" caption="Updated game intro sequence" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_Season2_CrowdIntro.jpg" alt="Redesigned crowd section" caption="Redesigned crowd section" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_Season2_Crowd1.jpg" alt="Redesigned crowd section" caption="Redesigned crowd section" />
                            <ImageWithCaption
                                src="/projects/1vs100/1vs100_Season2_OneTakesMoney1.jpg"
                                alt="New Taking the money sequence"
                                caption="New Taking the money sequence"
                            />
                            <ImageWithCaption src="/projects/1vs100/1vs100_Holiday_HostBooth.jpg" alt="Live Host with Holiday theming" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_Holiday_Set.jpg" alt="1 vs. 100 Arena with Holiday theming" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_NewYears_HostBooth.jpg" alt="Live Host with New Year's theming" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_NewYears_Set.jpg" alt="1 vs. 100 Arena with New Year's theming" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_SprintNow.jpg" alt="1 vs. 100 Branded Sprint widget" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_Season2_EPTheme1.jpg" alt="1 vs. 100 Extended Play Video Game theme" />
                            <ImageWithCaption src="/projects/1vs100/1vs100_Season2_EPTheme2.jpg" alt="1 vs. 100 Extended Play Movies theme" />
                            <ImageWithCaption src="/projects/1vs100/1v_Ext.jpg" alt="1 vs. 100 Extended Play Arena" />
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
            <ThemeProvider tint="dark">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ImageGallery>
                            <video controls css={[t.w_full]}>
                                <source src="/projects/1vs100/1vs100_PT_Intro.webm" type="video/webm" />
                                <source src="/projects/1vs100/1vs100_PT_Intro.mp4" type="video/mp4" />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                            <video controls css={[t.w_full]}>
                                <source src="/projects/1vs100/1vs100_PT_RoundSummary.webm" type="video/webm" />
                                <source src="/projects/1vs100/1vs100_PT_RoundSummary.mp4" type="video/mp4" />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                            <video controls css={[t.w_full]}>
                                <source src="/projects/1vs100/1vs100_EP_QA.webm" type="video/webm" />
                                <source src="/projects/1vs100/1vs100_EP_QA.mp4" type="video/mp4" />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                            <video controls css={[t.w_full]}>
                                <source src="/projects/1vs100/1vs100_EP_Bonus.webm" type="video/webm" />
                                <source src="/projects/1vs100/1vs100_EP_Bonus.mp4" type="video/mp4" />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
        </ProjectSubPage>
    );
}
