import { css } from '@emotion/react';

export const listStyleType = {
    list_none: css`
        list-style-type: none;
    `,
    list_disc: css`
        list-style-type: disc;
    `,
    list_decimal: css`
        list-style-type: decimal;
    `,
};
