import { css } from '@emotion/react';

export const textOverflow = {
    truncate: css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `,
    overflow_ellipses: css`
        text-overflow: ellipsis;
    `,
    overflow_clip: css`
        text-overflow: clip;
    `,
};
