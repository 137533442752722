/** @jsxImportSource @emotion/react */
import React from 'react';
import t from '../../../theme/styles';
import { BrowserRouter as Router, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ProjectArticle } from '../ProjectArticle';
import { ProjectBanner } from '../ProjectBanner';
import { ProjectAside } from '../ProjectAside';
import { ProjectPage } from '../ProjectPage';
import { ProjectSectionContainer, ProjectSection, ProjectSectionHeading } from '../ProjectSection';
import { ThemeProvider } from '../../../contexts/ThemeContext';
import { ImageWithCaption } from '../../../components/imageWithCaption';
import { ImageGallery } from '../../../components/imageGallery';
import { ProjectOverview } from '../ProjectOverview';
import { ProjectSubPage } from '../ProjectSubPage';
import { TextLink } from '../../../components/TextLink';

export function ProjectPopcap() {
    let { path } = useRouteMatch();

    return (
        <ProjectPage title="PopCap">
            <ProjectBanner src="/projects/PopCap/PvZ_Header.jpg" alt="" />
            <ProjectArticle>
                <ProjectSectionContainer>
                    <ProjectOverview>
                        <ProjectSectionHeading label="Overview" />
                        <p>
                            I worked at PopCap for over 3 years and was on 5 different development teams. I was a part of small (2-10 person) teams working on prototypes for new or
                            revamped games. All of the prototypes were mobile free-to-play designs and built with Unity. While each prototype met the same eventual end, they all
                            took unique paths to get there and I truly learned tons about a very tricky area of UX and Game design.
                        </p>
                    </ProjectOverview>

                    <ProjectAside title="PopCap" company="EA" date="2015 - 2018" titleStyle={[t.type_6]}>
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Platform</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Mobile</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Tools</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Unity, C#</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Skills</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>UX Design, Game Design, UI Design, F2P Design, Prototyping, Creative Direction</div>
                        </div>
                    </ProjectAside>
                </ProjectSectionContainer>

                <Router>
                    <Switch>
                        <Route exact path={path} component={Article} />
                        <Route exact path={`${path}gallery`}>
                            <Gallery projectPath={path} />
                        </Route>
                    </Switch>
                </Router>
            </ProjectArticle>
        </ProjectPage>
    );
}

function Article() {
    return (
        <React.Fragment>
            <ThemeProvider tint="extra-light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="My Role" />
                        <p>
                            Most of my time at PopCap I was a UX Designer but on the last couple game concepts I was also doing game design and creative direction. Each team was
                            small, probably an average of 5, so I would handle the UX design, UI art, and a lot of the front-end development work. For some of the later concepts I
                            also spent a significant amount of my time pitching to executives within EA as well as a couple companies abroad. I'm not particularly good at it though
                            so probably don't hire me to focus on that.
                        </p>
                    </ProjectSection>
                    <ProjectSection>
                        <ProjectSectionHeading label="Challenges" />
                        <ul css={[t.list_disc, t.pl_4, t.font_body, t.type_1]}>
                            <li>Free-to-play game design mechanics are extremely difficult to design respectfully. They're just as hard to communicate.</li>
                            <li>Navigating EA's evolving gate process.</li>
                            <li>Maintaining a small focused development team within a large team culture.</li>
                        </ul>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
            <ThemeProvider tint="dark">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="PvZ3" />
                        <p>
                            My first project at PopCap was to help develop a concept for the next iteration in the Plants vs. Zombies franchise, built on a solid and modern mobile
                            free-to-play foundation. Adapting existing (and beloved) game mechanics to something that met the utility requirements of free-to-play design while also
                            satisfying our own creative quality bar was a tall order. Our final concept and prototype was centered around a dynamic PvZ world full of game modes and
                            quests, a customizable house and social neighborhood space, and bite-sized action oriented gameplay. It didn't meet the bar (or it just wasn't Clash
                            Royale?) but I still believe this was a strong concept.
                        </p>
                        <ImageGallery columns={3}>
                            <ImageWithCaption src="/projects/PopCap/PvZ_WorldMap_Seamus.png" alt="PvZ3 concept - Quest giver" caption="PvZ3 concept - Quest giver" />
                            <ImageWithCaption src="/projects/PopCap/PvZ_PlayerMenu.png" alt="PvZ3 concept - Player menu" caption="PvZ3 concept - Player menu" />
                            <ImageWithCaption src="/projects/PopCap/PvZ_WorldMap_Stronghold_Details.png" alt="PvZ3 concept - Strongholds" caption="PvZ3 concept - Strongholds" />
                        </ImageGallery>
                    </ProjectSection>
                    <ProjectSection>
                        <ProjectSectionHeading label="Design" />
                        <p>
                            One of the things I quickly learned on this project was just how complicated the meta designs of a free-to-play game can be. It isn't always easy to
                            pinpoint the reasons one game works so well, but another falls so flat. One valuable resource we had was access to Immersyve and a wealth of research on
                            player needs and motivation. We would regularly pitch new systems, frameworks, or loose concepts to Troy at Immersyve and we're given excellent guidance
                            about how these systems might or might not satisfy a specific set of motivations. As we developed concepts and connected them together it became
                            difficult to keep track of exactly how everything was fitting together. I'm a visual thinker, so I developed a language for simply mapping out every
                            resource, game mode, and game element and how they're connected. Dubbed 'Complectograms' these became a way for us to communicate a new idea to other
                            team members, spot gaps in our design and ultimately document the full system.
                        </p>
                        <ImageGallery>
                            <ImageWithCaption
                                src="/projects/PopCap/PvZ3_Complectogram.png"
                                alt="PvZ3 concept system map or 'Complectogram'"
                                caption="PvZ3 concept system map or 'Complectogram'"
                            />
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
            <ThemeProvider tint="light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="Prototypes" />
                        <p>Each prototype that I worked on followed a similar development trajectory:</p>
                        <ul css={[t.list_disc, t.pl_4, t.font_body, t.type_1]}>
                            <li>Deep dive into core mechanics to understand the potential for new or adjusted utility.</li>
                            <li>Map utility to f2p, routine, and social systems.</li>
                            <li>Prototype the entire foundation as fully as possible.</li>
                            <li>Playtest and iteration.</li>
                        </ul>
                        <p>
                            One of the more well-known prototypes within PopCap was our concept for a revamped Bejeweled Blitz. We (4 of us) developed the 'Realms' prototype in a
                            very short period of time and released it as a studio-wide playtest. We instrumented the build with basic analytics so not only was it a fun experience
                            but we got to see how engaging our concept actually was. I gathered character artwork from various old PopCap projects so I'm unable to properly
                            attribute the card art below.
                        </p>
                        <ImageGallery columns={3}>
                            <ImageWithCaption
                                src="/projects/PopCap/Realms_home.jpg"
                                alt="Bejeweled Blitz Realms prototype - Homescreen"
                                caption="Bejeweled Blitz Realms prototype - Homescreen"
                            />
                            <ImageWithCaption
                                src="/projects/PopCap/Realms_cards.jpg"
                                alt="Bejeweled Blitz Realms prototype - Card Select"
                                caption="Bejeweled Blitz Realms prototype - Card Select"
                            />
                            <ImageWithCaption
                                src="/projects/PopCap/Realms_gameplay.jpg"
                                alt="Bejeweled Blitz Realms prototype - Gameplay"
                                caption="Bejeweled Blitz Realms prototype - Gameplay"
                            />
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
        </React.Fragment>
    );
}

function Gallery(props: { projectPath: string }) {
    return (
        <ProjectSubPage projectPath={props.projectPath}>
            <ThemeProvider tint="dark">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="PvZ3" />
                        <TextLink href="/projects/PopCap/PvZ_DesignFramework.pdf" label="PvZ3 Design Framework" />
                        <ImageGallery columns={4}>
                            <ImageWithCaption src="/projects/PopCap/PvZ_WorldMap_Seamus.png" alt="PvZ3 concept - Quest giver" caption="PvZ3 concept - Quest giver" />
                            <ImageWithCaption src="/projects/PopCap/PvZ_PlayerMenu.png" alt="PvZ3 concept - Player menu" caption="PvZ3 concept - Player menu" />
                            <ImageWithCaption src="/projects/PopCap/PvZ_WorldMap_Stronghold_Details.png" alt="PvZ3 concept - Strongholds" caption="PvZ3 concept - Strongholds" />
                            <ImageWithCaption src="/projects/PopCap/PvZ_WorldMap_Quests.png" alt="PvZ3 concept - World Map" caption="PvZ3 concept - World Map" />
                            <ImageWithCaption src="/projects/PopCap/PvZ_Stronghold_Full_locked.jpg" alt="PvZ3 concept - Strongholds" caption="PvZ3 concept - Strongholds" />
                            <ImageWithCaption src="/projects/PopCap/PvZ_Screen_Stronghold_feed.png" alt="PvZ3 concept - Feed" caption="PvZ3 concept - Feed" />
                            <ImageWithCaption src="/projects/PopCap/PvZ_Screen_Stronghold.jpg" alt="PvZ3 concept - Strongholds" caption="PvZ3 concept - Strongholds" />
                        </ImageGallery>
                        <ImageGallery>
                            <ImageWithCaption
                                src="/projects/PopCap/PvZ3_Complectogram.png"
                                alt="PvZ3 concept system map or 'Complectogram'"
                                caption="PvZ3 concept system map or 'Complectogram'"
                            />
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
            <ThemeProvider tint="extra-dark">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="PvZ2 - Future" />

                        <ImageGallery>
                            <ImageWithCaption src="/projects/PopCap/PvZ2_Complectogram.png" alt="Complectogram for current PvZ2" caption="Complectogram for current PvZ2" />
                            <ImageWithCaption
                                src="/projects/PopCap/PvZ2_Future1_Complectogram.png"
                                alt="Complectogram for PvZ2 Future concept"
                                caption="Complectogram for PvZ2 Future concept"
                            />
                            <ImageWithCaption
                                src="/projects/PopCap/PvZ2_Phase1_Complectogram.png"
                                alt="Complectogram for PvZ2 Future Phase 1"
                                caption="Complectogram for PvZ2 Future Phase 1"
                            />
                            <ImageWithCaption
                                src="/projects/PopCap/PvZ2_Phase2_Complectogram.png"
                                alt="Complectogram for PvZ2 Future Phase 2"
                                caption="Complectogram for PvZ2 Future Phase 2"
                            />
                            <ImageWithCaption
                                src="/projects/PopCap/PvZ2_Phase1_Screenflow.png"
                                alt="Screen flow for a simplified PvZ menu flow"
                                caption="Screen flow for a simplified PvZ menu flow"
                            />
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
            <ThemeProvider tint="light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="Realms" />

                        <ImageGallery columns={3}>
                            <ImageWithCaption
                                src="/projects/PopCap/Realms_home.jpg"
                                alt="Bejeweled Blitz Realms prototype - Homescreen"
                                caption="Bejeweled Blitz Realms prototype - Homescreen"
                            />
                            <ImageWithCaption
                                src="/projects/PopCap/Realms_cards.jpg"
                                alt="Bejeweled Blitz Realms prototype - Card Select"
                                caption="Bejeweled Blitz Realms prototype - Card Select"
                            />
                            <ImageWithCaption
                                src="/projects/PopCap/Realms_gameplay.jpg"
                                alt="Bejeweled Blitz Realms prototype - Gameplay"
                                caption="Bejeweled Blitz Realms prototype - Gameplay"
                            />
                            <ImageWithCaption
                                src="/projects/PopCap/Realms_guild.jpg"
                                alt="Bejeweled Blitz Realms prototype - Homescreen"
                                caption="Bejeweled Blitz Realms prototype - Homescreen"
                            />
                            <ImageWithCaption
                                src="/projects/PopCap/Realms_guildBattle.jpg"
                                alt="Bejeweled Blitz Realms prototype - Card Select"
                                caption="Bejeweled Blitz Realms prototype - Card Select"
                            />
                            <ImageWithCaption
                                src="/projects/PopCap/Realms_shop.jpg"
                                alt="Bejeweled Blitz Realms prototype - Gameplay"
                                caption="Bejeweled Blitz Realms prototype - Gameplay"
                            />
                        </ImageGallery>
                        <ImageGallery>
                            <ImageWithCaption
                                src="/projects/PopCap/BejeweledBlitz2_Complectogram.png"
                                alt="Complectogram for current Blitz"
                                caption="Complectogram for current Blitz"
                            />
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
            <ThemeProvider tint="extra-light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="Misc" />
                        <ImageGallery>
                            <ImageWithCaption src="/projects/PopCap/HalfToad_Complectogram.png" alt="Complectogram for Kraken concept" caption="Complectogram for Kraken concept" />
                            <ImageWithCaption src="/projects/PopCap/ClashRoyale_Complectogram.png" alt="Complectogram for Clash Royale" caption="Complectogram for Clash Royale" />
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
        </ProjectSubPage>
    );
}
