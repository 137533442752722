/** @jsxImportSource @emotion/react */
import React from 'react';
import t from '../../theme/styles';
import { useCarousel } from './Carousel';

export function CarouselPagination() {
    const { activeIndex, slideCount } = useCarousel();

    if (slideCount === 0) return null;

    const dots: JSX.Element[] = [];
    for (let i = 0; i < slideCount; i++) {
        dots.push(<div key={i} css={[t.bg_tint_1, t.rounded_full, t.size('6px'), activeIndex === i ? t.opacity_100 : t.opacity_40, t.mr_2]} />);
    }

    return <div css={[t.flex, t.child('div', [t.lastChild(t.mr_0)])]}>{dots}</div>;
}
