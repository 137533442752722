import { css } from '@emotion/react';

export const cursor = {
    cursor_auto: css`
        cursor: auto;
    `,
    cursor_default: css`
        cursor: default;
    `,
    cursor_pointer: css`
        cursor: pointer;
    `,
    cursor_wait: css`
        cursor: wait;
    `,
    cursor_text: css`
        cursor: text;
    `,
    cursor_move: css`
        cursor: move;
    `,
    cursor_not_allowed: css`
        cursor: not-allowed;
    `,
};
