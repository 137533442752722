import { css } from '@emotion/react';

export const letterSpacing = {
    tracking_tighter: css`
        letter-spacing: -0.05em;
    `,
    tracking_tight: css`
        letter-spacing: -0.025em;
    `,
    tracking_normal: css`
        letter-spacing: 0em;
    `,
    tracking_wide: css`
        letter-spacing: 0.025em;
    `,
    tracking_wider: css`
        letter-spacing: 0.05em;
    `,
    tracking_widest: css`
        letter-spacing: 0.1em;
    `,
};
