/** @jsxImportSource @emotion/react */
import React from 'react';
import t from '../../theme/styles';
import { ProjectSection, ProjectSectionContainer, ProjectSectionHeading } from '../projects/ProjectSection';
import { ProjectOverview } from '../projects/ProjectOverview';
import { ProjectAside } from '../projects/ProjectAside';
import { ProjectArticle } from '../projects/ProjectArticle';
import { ProjectPage } from '../projects/ProjectPage';
import { ProjectBanner } from '../projects/ProjectBanner';
import { PortfolioReviewFooter } from './PortfolioReviewFooter';
import { ThemeProvider } from '../../contexts/ThemeContext';
import { ImageGallery } from '../../components/imageGallery';
import { ImageWithCaption } from '../../components/imageWithCaption';
import { TextLink } from '../../components/TextLink';

export function PortfolioReviewPopcap() {
    return (
        <ProjectPage>
            <ProjectBanner src="/projects/PopCap/PvZ_Header.jpg" alt="" />
            <ProjectArticle>
                <ProjectSectionContainer>
                    <ProjectOverview>
                        <ProjectSectionHeading label="Overview" />
                        <p>
                            I worked at PopCap for over 3 years and was on 5 different development teams. I was a part of small (2-10 person) teams working on prototypes for new or
                            revamped games. All of the prototypes were mobile free-to-play designs and built with Unity. While each prototype met the same eventual end, they all
                            took unique paths to get there and I truly learned tons about a very tricky area of UX and Game design.
                        </p>
                    </ProjectOverview>

                    <ProjectAside title="PopCap" company="EA" date="2015 - 2018" titleStyle={[t.type_6]}>
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Platform</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Mobile</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Tools</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Unity, C#</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Skills</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>UX Design, Game Design, UI Design, F2P Design, Prototyping, Creative Direction</div>
                        </div>
                    </ProjectAside>
                </ProjectSectionContainer>
                <ThemeProvider tint="dark">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="PvZ3" />
                            <ul>
                                <li>"Labs" Project</li>
                                <li>~ 7 person team</li>
                                <li>3 major prototypes (in Unity)</li>
                            </ul>
                            <ImageGallery columns={4}>
                                <ImageWithCaption src="/projects/PopCap/PvZ_WorldMap_Seamus.png" alt="PvZ3 concept - Quest giver" caption="PvZ3 concept - Quest giver" />
                                <ImageWithCaption src="/projects/PopCap/PvZ_WorldMap_Quests.png" alt="PvZ3 concept - World Map" caption="PvZ3 concept - World Map" />
                                <ImageWithCaption src="/projects/PopCap/PvZ_PlayerMenu.png" alt="PvZ3 concept - Player menu" caption="PvZ3 concept - Player menu" />
                                <ImageWithCaption
                                    src="/projects/PopCap/PvZ_WorldMap_Stronghold_Details.png"
                                    alt="PvZ3 concept - Strongholds"
                                    caption="PvZ3 concept - Strongholds"
                                />
                            </ImageGallery>
                            <ImageGallery>
                                <ImageWithCaption src="/projects/PopCap/PvZ_Screen_Stronghold.jpg" alt="PvZ3 concept - Strongholds" caption="PvZ3 concept - Strongholds" />
                            </ImageGallery>

                            <div css={[t.mt_8]}>
                                <h3 css={[t.type_3]}>How did we communicate all this?</h3>
                                <TextLink href="/projects/PopCap/PvZ_DesignFramework.pdf" label="PvZ3 Design Framework" />
                            </div>
                        </ProjectSection>
                        <ProjectSection style={[t.mt_6]}>
                            <ProjectSectionHeading label="System Maps (or 'Complectograms')" />
                            <ImageGallery>
                                <ImageWithCaption src="/projects/PopCap/PvZ3_Complectogram.png" alt="PvZ3 system map or 'Complectogram'" />
                            </ImageGallery>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
                <ThemeProvider tint="light">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="PvZ2 - Future" />

                            <ImageGallery>
                                <ImageWithCaption src="/projects/PopCap/PvZ2_Complectogram.png" alt="Complectogram for current PvZ2" caption="Complectogram for current PvZ2" />
                            </ImageGallery>
                            <ImageGallery columns={2}>
                                <ImageWithCaption src="/projects/PopCap/PvZ2_Phase1_Complectogram.png" alt="Phase 1" caption="Phase 1" />
                                <ImageWithCaption src="/projects/PopCap/PvZ2_Phase2_Complectogram.png" alt="Phase 2" caption="Phase 2" />
                            </ImageGallery>
                            <ImageGallery>
                                <ImageWithCaption
                                    src="/projects/PopCap/PvZ2_Phase1_Screenflow.png"
                                    alt="Screen flow for a simplified PvZ menu flow"
                                    caption="Screen flow for a simplified PvZ menu flow"
                                />
                            </ImageGallery>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
                <ThemeProvider tint="dark">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="BONUS!" />
                            <ProjectSectionHeading label="Concur Travel Graph" />
                            <ul>
                                <li>Brief: Highlight gaps/opportunities within the current set of Concur mobile product suite. Come up with concepts to address them.</li>
                                <li>Communicate learnings to Concur leadership</li>
                                <li>Interactive infographic - D3</li>
                            </ul>
                        </ProjectSection>
                        <ImageGallery>
                            <ImageWithCaption src="/projects/Clients/Journey_BOTH_Incubation.png" alt="Interactive Traveler's Journey Map" caption="" />
                            <ImageWithCaption
                                src="/projects/Clients/Journey_BOTH_Incubation_all.png"
                                alt="Interactive Traveler's Journey Map"
                                caption="Interactive Traveler's Journey Map"
                            />
                        </ImageGallery>
                    </ProjectSectionContainer>
                </ThemeProvider>
            </ProjectArticle>
            <PortfolioReviewFooter />
        </ProjectPage>
    );
}
