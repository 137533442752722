import { css } from '@emotion/react';
import { size } from './utilities';

export const customStyles = {
    size: size,

    pageWidth: css`
        max-width: 1080px;
        margin: auto;
    `,
};
