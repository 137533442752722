import { css } from '@emotion/react';

export const overflow = {
    overflow_auto: css`
        overflow: auto;
    `,
    overflow_hidden: css`
        overflow: hidden;
    `,
    overflow_visible: css`
        overflow: visible;
    `,
    overflow_scroll: css`
        overflow: scroll;
    `,
    overflow_x_auto: css`
        overflow-x: auto;
    `,
    overflow_x_hidden: css`
        overflow-x: hidden;
    `,
    overflow_x_visible: css`
        overflow-x: visible;
    `,
    overflow_x_scroll: css`
        overflow-x: scroll;
    `,
    overflow_y_auto: css`
        overflow-y: auto;
    `,
    overflow_y_hidden: css`
        overflow-y: hidden;
    `,
    overflow_y_visible: css`
        overflow-y: visible;
    `,
    overflow_y_scroll: css`
        overflow-y: scroll;
    `,
};
