import { css } from '@emotion/react';

export const fontStyle = {
    not_italic: css`
        font-style: normal;
    `,
    italic: css`
        font-style: italic;
    `,
};
