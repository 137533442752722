import { css } from '@emotion/react';
import { CSSStyle } from './styles';

const smPX = 480;
const mdPX = 704;
const lgPX = 960;
const xlPX = 1120;

export const breakpoints = {
    sm: (styles: CSSStyle) => {
        return css`
            @media screen and (min-width: ${smPX}px) {
                ${styles}
            } ;
        `;
    },
    md: (styles: CSSStyle) => {
        return css`
            @media screen and (min-width: ${mdPX}px) {
                ${styles}
            } ;
        `;
    },
    lg: (styles: CSSStyle) => {
        return css`
            @media screen and (min-width: ${lgPX}px) {
                ${styles}
            } ;
        `;
    },
    xl: (styles: CSSStyle) => {
        return css`
            @media screen and (min-width: ${xlPX}px) {
                ${styles}
            } ;
        `;
    },

    media_hover: (styles: CSSStyle) => {
        return { '@media (hover:hover)': styles };
    },
    media_no_hover: (styles: CSSStyle) => {
        return { '@media (hover:none)': styles };
    },
    smPX,
    mdPX,
    lgPX,
    xlPX,
};
