/** @jsxImportSource @emotion/react */
import React from 'react';
import t from '../../../theme/styles';
import { BrowserRouter as Router, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ProjectArticle } from '../ProjectArticle';
import { ProjectBanner } from '../ProjectBanner';
import { ProjectAside } from '../ProjectAside';
import { ProjectPage } from '../ProjectPage';
import { ProjectSectionContainer, ProjectSection, ProjectSectionHeading } from '../ProjectSection';
import { ThemeProvider } from '../../../contexts/ThemeContext';
import { ImageWithCaption } from '../../../components/imageWithCaption';
import { ImageGallery } from '../../../components/imageGallery';
import { ProjectOverview } from '../ProjectOverview';

export function ProjectXbox() {
    let { path } = useRouteMatch();

    return (
        <ProjectPage title="Xbox">
            <ProjectBanner src="/projects/Xbox/Xbox_Header.jpg" alt="" />
            <ProjectArticle>
                <ProjectSectionContainer>
                    <ProjectOverview>
                        <ProjectSectionHeading label="Overview" />
                        <p>
                            I was a member of the Xbox Design group and worked on multiple apps, dashboard iterations and prototypes. The Kinect was as heavy focus for the company
                            during my tenure at Xbox, so most of these projects involved integrating gesture/voice commands. This also led to a lot of interaction prototyping and
                            experimentation.
                        </p>
                    </ProjectOverview>

                    <ProjectAside title="Xbox Dashboard" company="Microsoft" date="2011 - 2012">
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Platform</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Xbox 360</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Tools</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>XUI, Lua</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Skills</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Integration, Scripting, Management</div>
                        </div>
                    </ProjectAside>
                </ProjectSectionContainer>

                <Router>
                    <Switch>
                        <Route exact path={path} component={Article} />
                        <Route exact path={`${path}gallery`} component={Gallery} />
                    </Switch>
                </Router>
            </ProjectArticle>
        </ProjectPage>
    );
}

function Article() {
    return (
        <React.Fragment>
            <ThemeProvider tint="extra-light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="My Role" />
                        <p>
                            I was a "Design Developer" within the Xbox Design group. The role was essentially a bridge postion between design disciplines (UI/UX/Motion) and the
                            engineering and product management groups. The primary responsiblity was to integrate the designs using XUI, the Xbox's built-in UI toolset. XUI was a
                            timeline editor similar to Flash, except sporatically supported. The secondary responsibility, as you might imagine for a role in between two large
                            groups, was to communicate the goals, priorities and constraints back and forth.
                        </p>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
            <ThemeProvider tint="dark">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="Xbox Apps" />
                        <p>
                            Microsoft developed a lot of the partner applications at the time (Netflix, Hulu, Skype, etc.), probably because they required using XUI. This meant a
                            lot of concurrent development and resource balancing. I managed a team of design developers and was the lead on multiple applications, including the
                            first iteration of the ESPN app.
                        </p>
                        <h3 css={[t.font_heading, t.font_bold, t.type_3, t.mb_2]}>ESPN on Xbox</h3>
                        <p>Integrated 3D set, camera system and core UI. Co-designed and developed College Picks interface and feedback.</p>
                    </ProjectSection>
                    <ImageGallery columns={2}>
                        <ImageWithCaption src="/projects/ESPN/18_01.jpg" alt="Original ESPN on Xbox Hub area" caption="Original ESPN on Xbox Hub area" />
                        <ImageWithCaption
                            src="/projects/ESPN/College-Bowl-Showdown-main.jpg"
                            alt="College Football Pick ’em interface"
                            caption="College Football Pick ’em interface"
                        />
                    </ImageGallery>
                </ProjectSectionContainer>
            </ThemeProvider>
            <ThemeProvider tint="light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="2011 Xbox Dashboard Update" />
                        <p>
                            For my largest project, the 2011 Dashboard update, I lead the prototyping of new experience flows, user controls and Kinect gesture interactions. I then
                            partnered with platform engineers to integrate these new interactions into production code. I managed and was a member of a team of design developers
                            integrating these new controls and interactions into the various sub-sections of the Xbox Dashboard.
                        </p>
                    </ProjectSection>
                    <ImageGallery columns={3}>
                        <ImageWithCaption
                            src="/projects/Xbox/WireframeDashProto.jpg"
                            alt="Dashboard wireframe prototype"
                            caption="Dashboard wireframe prototype. Used in usability studies focused on Kinect gestures"
                        />
                        <ImageWithCaption src="/projects/Xbox/xF_concept_01.jpg" alt="Initial dashboard update design" caption="Initial dashboard update design" />
                        <ImageWithCaption src="/projects/Xbox/E3DashProto.jpg" alt="E3 Dashboard Demo" caption="E3 Dashboard Demo" />
                    </ImageGallery>
                    <ImageGallery>
                        <video controls css={[t.w_full]}>
                            <source src="/projects/Xbox/XboxDashboardPrototype.webm" type="video/webm" />
                            <source src="/projects/Xbox/XboxDashboardPrototype.mp4" type="video/mp4" />
                            Sorry, your browser doesn't support embedded videos.
                        </video>
                    </ImageGallery>
                </ProjectSectionContainer>
            </ThemeProvider>
            <ThemeProvider tint="extra-dark">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="Xbox One" />
                        <p>
                            Prototyped initial design vision and collaborated with the design team on new Kinect gestures and interactions. The resulting prototype was developed in
                            WPF and was primarily used for presentations/demos with executives. Sadly, I don't have any assets to show from this prototype.
                        </p>
                    </ProjectSection>
                    <ImageGallery>
                        <ImageWithCaption src="/projects/Xbox/Xbox-One-dashboard.jpg" alt="Xbox One Dashboard" caption="Xbox One Dashboard" />
                    </ImageGallery>
                </ProjectSectionContainer>
            </ThemeProvider>
        </React.Fragment>
    );
}

function Gallery() {
    return (
        <React.Fragment>
            <ThemeProvider tint="dark">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="Xbox Apps" />
                    </ProjectSection>
                    <ImageGallery columns={2}>
                        <ImageWithCaption src="/projects/ESPN/18_01.jpg" alt="Original ESPN on Xbox Hub area" caption="Original ESPN on Xbox Hub area" />
                        <ImageWithCaption
                            src="/projects/ESPN/College-Bowl-Showdown-main.jpg"
                            alt="College Football Pick ’em interface"
                            caption="College Football Pick ’em interface"
                        />
                    </ImageGallery>
                </ProjectSectionContainer>
            </ThemeProvider>
            <ThemeProvider tint="light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="2011 Xbox Dashboard Update" />
                    </ProjectSection>
                    <ImageGallery columns={3}>
                        <ImageWithCaption
                            src="/projects/Xbox/WireframeDashProto.jpg"
                            alt="Dashboard wireframe prototype"
                            caption="Dashboard wireframe prototype. Used in usability studies focused on Kinect gestures"
                        />
                        <ImageWithCaption src="/projects/Xbox/xF_concept_01.jpg" alt="Initial dashboard update design" caption="Initial dashboard update design" />
                        <ImageWithCaption src="/projects/Xbox/E3DashProto.jpg" alt="E3 Dashboard Demo" caption="E3 Dashboard Demo" />
                    </ImageGallery>
                    <ImageGallery>
                        <video controls css={[t.w_full]}>
                            <source src="/projects/Xbox/XboxDashboardPrototype.webm" type="video/webm" />
                            <source src="/projects/Xbox/XboxDashboardPrototype.mp4" type="video/mp4" />
                            Sorry, your browser doesn't support embedded videos.
                        </video>
                    </ImageGallery>
                </ProjectSectionContainer>
            </ThemeProvider>
            <ThemeProvider tint="extra-dark">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="Xbox One" />
                    </ProjectSection>
                    <ImageGallery>
                        <ImageWithCaption src="/projects/Xbox/Xbox-One-dashboard.jpg" alt="Xbox One Dashboard" caption="Xbox One Dashboard" />
                    </ImageGallery>
                </ProjectSectionContainer>
            </ThemeProvider>
        </React.Fragment>
    );
}
