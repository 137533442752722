/** @jsxImportSource @emotion/react */
import React from 'react';
import Zoom from 'react-medium-image-zoom';
import t from '../theme/styles';

export function ImageWithCaption(props: { src: string; alt: string; caption?: string }) {
    return (
        <div css={[t.flex, t.flex_col]}>
            <Zoom zoomMargin={24} classDialog="custom-zoom">
                <img src={props.src} alt={props.alt} css={[t.max_w_full, t.h_auto]} />
            </Zoom>
            {props.caption && <span css={[t.mt_2, t.type_0]}>{props.caption}</span>}
        </div>
    );
}
