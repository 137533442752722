/** @jsxImportSource @emotion/react */
import React from 'react';
import t from '../theme/styles';
import { Page } from './page';

export function Footer() {
    return (
        <footer css={[t.bg_dark_1, t.flex_initial]}>
            <Page style={[t.py_4, t.flex, t.flex_row]}>
                <p css={[t.flex_auto, t.flex, t.items_center, t.text_tint_5, t.font_heading, t.type_0, t.sm([t.type_1])]}>Designed & developed by Colin Riley</p>
                <ul>
                    <li>
                        <a css={[t.opacity_40, t.hover([t.opacity_100])]} href="https://www.linkedin.com/in/colinmriley" target="_blank" rel="noreferrer">
                            <img css={[t.h('24px')]} src="/LI-In-Bug.png" alt="LinkedIn" />
                        </a>
                    </li>
                </ul>
            </Page>
        </footer>
    );
}
