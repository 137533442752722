/** @jsxImportSource @emotion/react */
import React from 'react';
import { Carousel, CarouselProvider, useCarousel } from '../../components/Carousel/Carousel';
import { CarouselNavigationButton } from '../../components/Carousel/CarouselNavigationButton';
import { CarouselPagination } from '../../components/Carousel/CarouselPagination';
import { Page } from '../../components/page';
import t from '../../theme/styles';

export function HomepageQuotes() {
    return (
        <div css={[t.bg_primary_1]}>
            <Page style={[t.pt_9, t.pb_9]}>
                <div css={[t.relative, t.flex, t.flex_col, t.justify_center, t.items_center, t.min_h('400px'), t.px_7, t.md([t.px_8])]}>
                    <CarouselProvider>
                        <Carousel>
                            <Quote
                                index={0}
                                quote="Colin has contributed to all areas and stages of product development as an artist, interactive designer, organizer, and engineer. Always willing to take on new
                    challenges and learn, he is the most consistent, dependable developer I have ever worked with."
                                cite="Matt Van Gorder, Creative Lead, 1 vs. 100"
                            />
                            <Quote
                                index={1}
                                quote="Colin is the kind of UI Designer most teams dream of having. In the year or so that I worked with him, he greatly improved our UI quality and processes. His knowledge and understanding of UI design and implementation is vast, and he has the work ethic, resourcefulness, and drive for excellence needed to rapidly and continually deliver high-quality work."
                                cite="Michael Shreffler, Senior Product Manager, Midwinter Entertainment"
                            />
                            <Quote
                                index={2}
                                quote="He is a force of nature when set loose to implement and refine the player experience. He will bring more than that to your project, though - a keen eye for process that enables creativity while ensuring productivity, he will act as a force multiplier for your entire team when given the leverage to do so."
                                cite="Ben Batstone, Metagame/UI/UX Lead, Midwinter Entertainment"
                            />
                            <Quote
                                index={3}
                                quote="Despite starting from scratch, with no game engine, tools, or content pipeline –
                                Colin consistently found ways to work with what he had, build what he did not,
                                and bring the vision for 1 vs 100 to life on Xbox 360."
                                cite="Chuck Noble, Developer, 1 vs. 100"
                            />
                            <Quote
                                index={4}
                                quote="Colin is an amazing designer, an emphatic collaborator, and sets the gold
                                standard for his discipline."
                                cite="Anonymous Peer, Amazon"
                            />
                            <Quote
                                index={5}
                                quote="In my opinion, he is the glue to the team. Without him, this project wouldn't be held together quite the same. He has definitely earned my trust over the months."
                                cite="Anonymous Peer, Amazon"
                            />
                            <Quote
                                index={6}
                                quote="In everything from systems design to studio culture, Colin has a very broad, analytical perspective. He excels in helping others to define and understand the underlying goals and motivations behind what they want to do, often resulting in a fundamental tilt towards a better product."
                                cite="Anonymous Peer, Amazon"
                            />
                            <Quote
                                index={7}
                                quote="Colin is a thoughtful and direct communicator who is not shy about raising issues tactfully and in a solution-focused manner. He has a strong eye for identifying gaps in UX designs, asking the right questions in team meetings, and ensuring work is properly prioritized and tracked."
                                cite="Michael Shreffler, Senior Product Manager, Midwinter Entertainment"
                            />
                        </Carousel>
                        <CarouselPagination />

                        <div css={[t.absolute, t.inset_0, t.z_10, t.flex, t.flex_row, t.text_tint_1]}>
                            <div css={[t.flex_initial, t.flex, t.flex_col, t.justify_center]}>
                                <CarouselNavigationButton direction="previous" />
                            </div>
                            <div css={[t.flex_auto]} />
                            <div css={[t.flex_initial, t.flex, t.flex_col, t.justify_center]}>
                                <CarouselNavigationButton direction="next" />
                            </div>
                        </div>
                    </CarouselProvider>
                </div>
            </Page>
        </div>
    );
}

function Quote(props: { index: number; quote: string; cite?: string }) {
    const { activeIndex } = useCarousel();

    return (
        <figure css={[t.max_w('540px'), props.index !== activeIndex ? t.hidden : [t.flex, t.flex_col]]}>
            <blockquote css={[t.font_heading, t.text_tint_1, t.type_3, t.text_center, t.md([t.type_4])]}>&ldquo;{props.quote}&rdquo;</blockquote>
            {props.cite && (
                <figcaption css={[t.mt_3, t.flex, t.flex_col, t.items_end]}>
                    <cite css={[t.text_tint_1, t.font_body, t.not_italic, t.type_1, t.text_right, t.sm([t.mr_4]), t.md([t.mr_6, t.type_2]), t.lg(t.mr_8)]}>
                        &mdash;&nbsp;{props.cite}
                    </cite>
                </figcaption>
            )}
        </figure>
    );
}
