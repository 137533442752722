/** @jsxImportSource @emotion/react */
import React from 'react';
import t from '../../../theme/styles';
import { ProjectArticle } from '../ProjectArticle';
import { ProjectBanner } from '../ProjectBanner';
import { ProjectAside } from '../ProjectAside';
import { ProjectPage } from '../ProjectPage';
import { ProjectSectionContainer, ProjectSection, ProjectSectionHeading } from '../ProjectSection';
import { ThemeProvider } from '../../../contexts/ThemeContext';
import { ImageWithCaption } from '../../../components/imageWithCaption';
import { ImageGallery } from '../../../components/imageGallery';
import { ProjectOverview } from '../ProjectOverview';

export function ProjectClients() {
    return (
        <ProjectPage title="Client work">
            <ProjectBanner src="/projects/Clients/Concur_Header.jpg" alt="" />
            <ProjectArticle>
                <ProjectSectionContainer>
                    <ProjectOverview>
                        <ProjectSectionHeading label="Overview" />
                        <p>
                            While working for the design firm Intentional Futures, I worked with multiple clients on a variety of projects usually focused on either concepting or
                            information display. Some of these projects were on extremely tight timeframes, a single day in some cases. For some of the longer projects I was able
                            to apply some of my technical skills and developed prototypes or demos.
                        </p>
                    </ProjectOverview>

                    <ProjectAside title="Client work" company="Intentional Futures" date="2014">
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Platform</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Web and Print</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Tools</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Illustrator, Javascript, D3</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Skills</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>UX Design, UI Design, Prototyping</div>
                        </div>
                    </ProjectAside>
                </ProjectSectionContainer>

                <ThemeProvider tint="extra-light">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="My Role" />
                            <p>
                                Another hybrid position for me. Sometimes I was doing pure design work like developing UX flows or infographics, at other times I was doing software
                                development for prototypes, and in a few rare cases I helped do research into emerging technologies like VR.
                            </p>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
                <ThemeProvider tint="dark">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="Gates Foundation" />
                            <p>
                                Intentional Futures had various projects with the Gates Foundation, often involving synthesizing research. Occasionally, they would ask us to
                                develop concepts for potential endeavours. The images below were for concepts related to their Charitable Support Sector group, looking at ways to
                                improve the efficacy of charities in general.
                            </p>
                        </ProjectSection>
                        <ImageGallery columns={3}>
                            <ImageWithCaption src="/projects/Clients/Morningstar2.png" alt="Giving Manager concept" caption="Giving Manager concept" />
                            <ImageWithCaption src="/projects/Clients/CommunityConnect-2.png" alt="Community Connect concept" caption="Community Connect concept" />
                            <ImageWithCaption src="/projects/Clients/990-2.png" alt="Turbo 990 concept" caption="Turbo 990 concept" />
                        </ImageGallery>
                    </ProjectSectionContainer>
                </ThemeProvider>
                <ThemeProvider tint="extra-dark">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="Concur Travel Graph" />
                            <div css={[t.flex]}>
                                <div css={[t.flex_auto, t.mr_8]}>
                                    <p>
                                        For our client, Concur, a corporate travel company, we were asked to help them diagnose the overall business traveler experience and bring
                                        to light potential areas for improvement or innovation.
                                    </p>
                                    <h3 css={[t.font_heading, t.font_bold, t.type_3, t.mb_2]}>Goals</h3>
                                    <ul css={[t.list_disc, t.pl_4, t.font_body, t.type_1]}>
                                        <li>Highlight the complexity and emotional range of a business traveler journey from booking to completion.</li>
                                        <li>Provide insight into where there may be gaps in their experiences and opportunities for new business exist.</li>
                                        <li>Provide leadership with a tool to help understand the potential impact of their strategies.</li>
                                    </ul>
                                </div>
                                <div css={[t.flex0]}>
                                    <ImageGallery style={[t.w('300px')]}>
                                        <ImageWithCaption
                                            src="/projects/Clients/Radial_Journey.png"
                                            alt="Radial version of a traveler's journey"
                                            caption="Radial version of a traveler's journey"
                                        />
                                    </ImageGallery>
                                </div>
                            </div>
                        </ProjectSection>
                        <ImageGallery>
                            <ImageWithCaption src="/projects/Clients/Journey_BOTH_Incubation.png" alt="Interactive Traveler's Journey Map" caption="" />
                            <ImageWithCaption
                                src="/projects/Clients/Journey_BOTH_Incubation_all.png"
                                alt="Interactive Traveler's Journey Map"
                                caption="Interactive Traveler's Journey Map"
                            />
                        </ImageGallery>
                    </ProjectSectionContainer>
                </ThemeProvider>
                <ThemeProvider tint="light">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="Thereport" />
                            <p>
                                Another project with Concur looking at the potential for using bluetooth tagging technology. My task was to create a visual aid for a series of
                                narratives about how the technology would assist different users.
                            </p>
                            <h3 css={[t.font_heading, t.font_bold, t.type_3, t.mb_2]}>Goals</h3>
                            <ul css={[t.list_disc, t.pl_4, t.font_body, t.type_1]}>
                                <li>Provide clear and compelling visuals that show how new technology could be integrated.</li>
                                <li>Illustrate various scenarios enabled by this new technology.</li>
                            </ul>
                        </ProjectSection>
                        <ImageGallery>
                            <ImageWithCaption src="/projects/Clients/Thereport_Terminal.jpg" alt="Thereport with location callouts" caption="Thereport with location callouts" />
                        </ImageGallery>
                        <ImageGallery columns={3}>
                            <ImageWithCaption src="/projects/Clients/Thereport_Garage.jpg" alt="Thereport - Garage" caption="Thereport - Garage" />
                            <ImageWithCaption src="/projects/Clients/Thereport_Security_2.jpg" alt="Thereport - Security check" caption="Thereport - Security check" />
                            <ImageWithCaption src="/projects/Clients/Thereport_Terminal_3.jpg" alt="Thereport - Terminal" caption="Thereport - Terminal" />
                        </ImageGallery>
                    </ProjectSectionContainer>
                </ThemeProvider>
            </ProjectArticle>
        </ProjectPage>
    );
}
