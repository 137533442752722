import { css, SerializedStyles } from '@emotion/react';
import { background } from './background';
import { backgroundImage } from './backgroundImage';
import { border } from './border';
import { breakpoints } from './breakpoints';
import { contentStyles } from './content';
import { cursor } from './cursor';
import { customStyles } from './custom';
import { display } from './display';
import { fill } from './fill';
import { flex } from './flex';
import { fontFamily } from './fontFamily';
import { fontStyle } from './fontStyle';
import { fontWeight } from './fontWeight';
import { grid } from './grid';
import { gridAuto } from './gridAuto';
import { gridColumn } from './gridColumn';
import { gridRow } from './gridRow';
import { height } from './height';
import { letterSpacing } from './letterSpacing';
import { listStyleType } from './listStyleType';
import { margin } from './margin';
import { objectFit } from './objectFit';
import { opacity } from './opacity';
import { outline } from './outline';
import { overflow } from './overflow';
import { padding } from './padding';
import { palette } from './palette';
import { pointerEvents } from './pointerEvents';
import { position } from './position';
import { pseudoClass } from './pseudoClass';
import { skew } from './skew';
import { spacing } from './spacing';
import { stroke } from './stroke';
import { textAlign } from './textAlign';
import { textColor } from './textColor';
import { textDecoration } from './textDecoration';
import { textOverflow } from './textOverflow';
import { textTransform } from './textTransform';
import { typeStyle } from './typeStyle';
import { visibility } from './visibility';
import { whitespace } from './whitespace';
import { width } from './width';
import { zIndex } from './zIndex';

export type Keyframes = {
    name: string;
    styles: string;
    anim: number;
    toString: () => string;
} & string;
export type CSSStyle = null | SerializedStyles | SerializedStyles[];

const HTMLReset = css`
    html {
        line-height: 1.15;
        scroll-behavior: auto;
        overflow: hidden;
        height: -webkit-fill-available;
    }
`;

const BodyReset = css`
    body: {
        margin: 0;
        overflow-x: hidden;
        min-height: 100vh;
        min-height: -webkit-fill-available;
    }
`;

const MainReset = css`
    main {
        display: block;
    }
`;

const Reset = css`
    * {
        margin: 0;
        box-sizing: border-box;
        border: 0;
        padding: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        font-size: 1em;
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-block-start: 0;
        margin-block-end: 0;
    }
`;

export const Global = [HTMLReset, BodyReset, MainReset, Reset];
const themeTokens = {
    Global,
    ...background,
    ...backgroundImage,
    ...border,
    ...breakpoints,
    ...contentStyles,
    ...cursor,
    ...customStyles,
    ...display,
    ...fill,
    ...flex,
    ...fontFamily,
    ...fontStyle,
    ...fontWeight,
    ...grid,
    ...gridAuto,
    ...gridColumn,
    ...gridRow,
    ...height,
    ...letterSpacing,
    ...listStyleType,
    ...margin,
    ...objectFit,
    ...opacity,
    ...outline,
    ...overflow,
    ...padding,
    ...palette,
    ...pointerEvents,
    ...position,
    ...pseudoClass,
    ...skew,
    ...spacing,
    ...stroke,
    ...textAlign,
    ...textColor,
    ...textDecoration,
    ...textOverflow,
    ...textTransform,
    ...typeStyle,
    ...visibility,
    ...whitespace,
    ...width,
    ...zIndex,
};

export default themeTokens;
