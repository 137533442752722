/** @jsxImportSource @emotion/react */
import React from 'react';
import { Link } from 'react-router-dom';
import { LogoIcon } from '../icons/logoIcon';
import t from '../theme/styles';
import { Page } from './page';

export function Header() {
    return (
        <div css={[t.bg_dark_3, t.absolute, t.inset_x_0, t.top_0, t.z_80, t.border_b, t.border_solid, t.border_dark_2]}>
            <Page>
                <Link to="/" css={[t.no_underline]}>
                    <div css={[t.text_primary_1, t.py_3, t.flex, t.items_center]}>
                        <LogoIcon />
                        <span css={[t.ml_3, t.font_heading, t.type_0, t.font_normal, t.tracking_widest]}>COLIN RILEY</span>
                    </div>
                </Link>
            </Page>
        </div>
    );
}
