/** @jsxImportSource @emotion/react */
import React from 'react';
import t, { CSSStyle } from '../theme/styles';

export function LogoIcon(props: { thin?: boolean; style?: CSSStyle }) {
    if (props.thin) {
        return (
            <svg role="img" width="507" height="465" viewBox="0 0 507 465" fill="none" css={[t.size('28px'), props.style]}>
                <path
                    fillRule="evenodd"
                    css={[t.fill_current]}
                    d="M487.098,232.681 L388.811,330.516 L506.895,445.256 L481.298,445.256 L377.419,341.855 L253.757,464.948 L130.095,341.855 L26.217,445.256 L0.619,445.256 L118.703,330.516 L20.416,232.681 L115.840,137.697 L0.619,25.738 L26.217,25.738 L127.266,126.323 L253.757,0.415 L380.248,126.323 L481.298,25.738 L506.895,25.738 L391.674,137.697 L487.098,232.681 ZM253.760,23.899 L44.010,232.685 L253.760,441.469 L463.510,232.685 L253.760,23.899 ZM313.572,298.398 L313.572,275.117 L354.788,234.090 L254.172,133.936 L153.555,234.090 L191.943,272.301 L191.943,295.583 L130.165,234.090 L254.171,110.654 L378.178,234.090 L313.572,298.398 Z"
                />
            </svg>
        );
    }
    return (
        <svg role="img" width="501" height="461" viewBox="0 0 501 461" fill="none" css={[t.size('28px'), props.style]}>
            <path
                fillRule="evenodd"
                css={[t.fill_current]}
                d="M480.707,230.098 L383.710,326.946 L500.244,440.531 L474.983,440.531 L372.468,338.171 L250.426,460.025 L128.385,338.171 L25.870,440.531 L0.608,440.531 L117.143,326.946 L20.145,230.098 L114.318,136.069 L0.608,25.240 L25.870,25.240 L125.595,124.810 L250.426,0.169 L375.258,124.811 L474.983,25.240 L500.244,25.240 L386.534,136.069 L480.707,230.098 ZM250.431,35.823 L55.852,230.104 L250.431,424.385 L445.010,230.104 L250.431,35.823 ZM301.006,304.046 L301.006,272.749 L337.255,235.767 L250.682,147.441 L164.109,235.767 L202.138,274.566 L202.138,305.874 L133.426,235.772 L250.677,116.149 L367.926,235.772 L301.006,304.046 Z"
            />
        </svg>
    );
}
