import { css } from '@emotion/react';

export function w(width: string) {
    return css`
        width: ${width};
    `;
}

export function h(height: string) {
    return css`
        height: ${height};
    `;
}

export function min_w(width: string) {
    return css`
        min-width: ${width};
    `;
}

export function min_h(height: string) {
    return css`
        min-height: ${height};
    `;
}

export function max_w(width: string) {
    return css`
        max-width: ${width};
    `;
}

export function max_h(height: string) {
    return css`
        max-height: ${height};
    `;
}

export function size(size: string | { width: string; height: string }) {
    const s = typeof size === 'string' ? { width: size, height: size } : size;
    return css`
        width: ${s.width};
        height: ${s.height};
    `;
}

export function top(position: string) {
    return css`
        top: ${position};
    `;
}
export function bottom(position: string) {
    return css`
        bottom: ${position};
    `;
}
export function left(position: string) {
    return css`
        left: ${position};
    `;
}
export function right(position: string) {
    return css`
        right: ${position};
    `;
}
export function pos(position: string | { top: string; right: string; bottom: string; left: string }) {
    const pos = typeof position === 'string' ? { top: position, right: position, bottom: position, left: position } : position;
    return css`
        top: ${pos.top};
        right: ${pos.right};
        bottom: ${pos.bottom};
        left: ${pos.left};
    `;
}
export function p(padding: string | { x: string; y: string } | { top: string; right: string; bottom: string; left: string }) {
    let pad = typeof padding === 'string' ? { top: padding, right: padding, bottom: padding, left: padding } : padding;
    pad = 'x' in pad ? { top: pad.y, right: pad.x, bottom: pad.y, left: pad.x } : pad;
    return css`
        padding-top: ${pad.top};
        padding-right: ${pad.right};
        padding-bottom: ${pad.bottom};
        padding-left: ${pad.left};
    `;
}
export function pt(width: string) {
    return css`
        padding-top: ${width};
    `;
}
export function pr(width: string) {
    return css`
        padding-right: ${width};
    `;
}
export function pb(width: string) {
    return css`
        padding-bottom: ${width};
    `;
}
export function pl(width: string) {
    return css`
        padding-left: ${width};
    `;
}
export function px(width: string) {
    return css`
        padding-left: ${width};
        padding-right: ${width};
    `;
}
export function py(height: string) {
    return css`
        padding-top: ${height};
        padding-bottom: ${height};
    `;
}
export function mt(width: string) {
    return css`
        margin-top: ${width};
    `;
}
export function mr(width: string) {
    return css`
        margin-right: ${width};
    `;
}
export function mb(width: string) {
    return css`
        margin-bottom: ${width};
    `;
}
export function ml(width: string) {
    return css`
        margin-left: ${width};
    `;
}
export function mx(width: string) {
    return css`
        margin-left: ${width};
        margin-right: ${width};
    `;
}
export function my(height: string) {
    return css`
        margin-top: ${height};
        margin-bottom: ${height};
    `;
}
export function text_color(color: string) {
    return css`
        color: ${color};
    `;
}
export function border_color(color: string) {
    return css`
        border-color: ${color};
    `;
}
export function background_color(color: string) {
    return css`
        background-color: ${color};
    `;
}
export function content(s: string) {
    return css`
        content: '${s}';
    `;
}
export function fontSize(size: string) {
    return css`
        font-size: ${size};
    `;
}
export function lineHeight(size: string) {
    return css`
        line-height: ${size};
    `;
}
export function textSize(size: string, height: string) {
    return css`
        font-size: ${size};
        line-height: ${height};
    `;
}
