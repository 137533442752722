import { css } from '@emotion/react';

export const opacity = {
    opacity_0: css`
        opacity: 0;
    `,
    opacity_10: css`
        opacity: 0.1;
    `,
    opacity_20: css`
        opacity: 0.2;
    `,
    opacity_30: css`
        opacity: 0.3;
    `,
    opacity_40: css`
        opacity: 0.4;
    `,
    opacity_50: css`
        opacity: 0.5;
    `,
    opacity_60: css`
        opacity: 0.6;
    `,
    opacity_70: css`
        opacity: 0.7;
    `,
    opacity_80: css`
        opacity: 0.8;
    `,
    opacity_90: css`
        opacity: 0.9;
    `,
    opacity_100: css`
        opacity: 1;
    `,
};
