import { css } from '@emotion/react';

export const visibility = {
    visible: css`
        visibility: visible;
    `,
    invisible: css`
        visibility: hidden;
    `,
};
