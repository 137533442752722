/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';
import { TextRouteLink } from '../../components/TextRouteLink';
import { ThemeProvider } from '../../contexts/ThemeContext';
import t from '../../theme/styles';
import { ProjectSection, ProjectSectionContainer } from './ProjectSection';

export function ProjectSubPage(props: { projectPath: string; children?: ReactNode }) {
    return (
        <React.Fragment>
            {props.children}
            <ThemeProvider tint="extra-dark">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <TextRouteLink to={props.projectPath} label="Return to project" style={[t.font_heading, t.type_2]} />
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
        </React.Fragment>
    );
}
