/** @jsxImportSource @emotion/react */
import React from 'react';
import t from '../../../theme/styles';
import { BrowserRouter as Router, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ProjectArticle } from '../ProjectArticle';
import { ProjectBanner } from '../ProjectBanner';
import { ProjectAside } from '../ProjectAside';
import { ProjectPage } from '../ProjectPage';
import { ProjectSectionContainer, ProjectSection, ProjectSectionHeading } from '../ProjectSection';
import { ThemeProvider } from '../../../contexts/ThemeContext';
import { ImageWithCaption } from '../../../components/imageWithCaption';
import { ImageGallery } from '../../../components/imageGallery';
import { ProjectOverview } from '../ProjectOverview';
import { ProjectSubPage } from '../ProjectSubPage';

export function ProjectDynamicWall() {
    let { path } = useRouteMatch();

    return (
        <ProjectPage title="Dynamic Wall">
            <ProjectBanner src="/projects/DynamicWall/Thumb_Goofall.png" alt="" />
            <ProjectArticle>
                <ProjectSectionContainer>
                    <ProjectOverview>
                        <ProjectSectionHeading label="Overview" />
                        <p>
                            The "Dynamic Wall" is a hardware/software product that combines hidden visual technology with engaging depth-sensing camera technology to produce
                            suprising and delightful experiences. The primary use cases involve lobby or large shared space settings, but we also experimented in musical
                            performance atmospheres. The original wall concept was developed by Pilot for Hilton and displayed in the Hilton Innovation Gallery.
                        </p>
                    </ProjectOverview>

                    <ProjectAside title="Dynamic Wall" company="Pilot Lab" date="2019" titleStyle={[t.type_6]}>
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Tools</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Electron, React, Typescript, Intel RealSense Depth Camera</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Skills</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Programming, UX Design, UI Design, Tool Development, Prototyping</div>
                        </div>
                    </ProjectAside>
                </ProjectSectionContainer>

                <Router>
                    <Switch>
                        <Route exact path={path} component={Article} />
                        <Route exact path={`${path}gallery`}>
                            <Gallery projectPath={path} />
                        </Route>
                        <Route exact path={`${path}demo`}>
                            <Demo projectPath={path} />
                        </Route>
                    </Switch>
                </Router>
            </ProjectArticle>
        </ProjectPage>
    );
}

function Article() {
    return (
        <React.Fragment>
            <ThemeProvider tint="extra-light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ImageGallery>
                            <video controls css={[t.w_full]}>
                                <source src="/projects/DynamicWall/Hilton_LED_wall.mp4" type="video/mp4" />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                            <span css={[t.type_0]}>Original installation of the dynamic wall at the Hilton Innovation Gallery</span>
                        </ImageGallery>
                        <ProjectSectionHeading label="My Role" style={[t.mt_5]} />
                        <p>
                            Primarily I would say my focus was on UX design and front-end development for the Controller, the software used to control and edit the visual scenes
                            displayed on the wall. Additionally, I was in charge of most of the hardware prototyping. I got to use a soldering gun for the first time since college.
                        </p>
                    </ProjectSection>
                    <ProjectSection>
                        <ProjectSectionHeading label="Challenges" />
                        <ul css={[t.list_disc, t.pl_4, t.font_body, t.type_1]}>
                            <li>Depth sensing cameras.</li>
                            <li>
                                Our visuals required tuning knobs for particles, physics, shaders, AND depth sensing data. This made the creative aspects of scene creation
                                difficult but also required a complex set of tools in our Controller software.
                            </li>
                        </ul>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>

            <ThemeProvider tint="light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="Product Development" />
                        <p>
                            When I joined Pilot, the project was being reconceptualized as a standalone product with a need for a sturdier technical foundation, better performance,
                            and a more robust editing toolset. With those goals in mind, my co-worker David Siems and I decided to rebuild from ground up. We landed on a
                            React-based Electron app and switched from Kinect to an Intel Realsense camera. The software is broken down into 4 components:
                        </p>
                        <ul css={[t.list_disc, t.pl_4, t.font_body, t.type_1]}>
                            <li>
                                Surface: our actual renderer on the computer hooked up to our display. We focused on a particle based simulation with an emphasis on transitions
                                between our various scenes.
                            </li>
                            <li>Controller: both the remote control and a scene editor for creating new visuals.</li>
                            <li>
                                Native Controller: a tablet version of the controller with very limited editing capabilities. Intended for distribution to customers with a wall
                                installation.
                            </li>
                            <li>Media Server: a central hub for scenes. Surfaces and controllers all communicated through this.</li>
                        </ul>

                        <p>
                            In addition to software prototyping, we also needed to learn more about which hardware solutions best fit our scenario. Let me tell you, trying to
                            cobble together any display technology you quickly fall into the deep end. We ended up experimenting with various led panels, learning about nits and
                            pitch and eventually narrowing down to the range of values that best worked behind the wood veneers.
                        </p>
                        <ImageGallery columns={2}>
                            <ImageWithCaption
                                src="/projects/DynamicWall/Wall_VeneerTest1.JPG"
                                alt="Testing wood veneers with prototype LED panels"
                                caption="Testing wood veneers with prototype LED panels"
                            />
                            <ImageWithCaption
                                src="/projects/DynamicWall/Wall_VeneerTest2.JPG"
                                alt="Testing wood veneers with prototype LED panels"
                                caption="Testing wood veneers with prototype LED panels"
                            />
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>

            <ThemeProvider tint="extra-light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="Controller" />
                        <p>
                            Our development process was very organic, focusing on iteration and feedback through studio-wide sprint demos. The controller grew within this
                            environment almost purely focused on functionality. We were the primary users, developing new controls or tools as we needed them to achieve our latest
                            demo.
                        </p>
                        <p>
                            Each visual is a collection of parameters, called a scene. Scenes are used to configure how data from the camera is turned into feedback. As we
                            developed more capabilities into the surface (physics, transitions, post-processing) we also needed to improve the controller. In the end, the biggest
                            problem was simply trying to navigate through so many controls and maintaining focus while editing. Below is an example of a scene and scene
                            configuration within the controller.
                        </p>
                        <ImageGallery>
                            <video controls css={[t.w_full]} poster="/projects/DynamicWall/Pinkfalls.jpg">
                                <source src="/projects/DynamicWall/Pinkfalls.webm" type="video/webm" />
                                <source src="/projects/DynamicWall/Pinkfalls.mp4" type="video/mp4" />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                        </ImageGallery>
                        <ImageGallery>
                            <ImageWithCaption
                                src="/projects/DynamicWall/Wall_Controller.JPG"
                                alt="Dynamic Wall scene sditor software"
                                caption="Dynamic Wall scene editor software"
                            />
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
        </React.Fragment>
    );
}

function Gallery(props: { projectPath: string }) {
    return (
        <ProjectSubPage projectPath={props.projectPath}>
            <ThemeProvider tint="light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ImageGallery columns={2}>
                            <ImageWithCaption
                                src="/projects/DynamicWall/Wall_VeneerTest1.jpg"
                                alt="Testing wood veneers with prototype LED panels"
                                caption="Testing wood veneers with prototype LED panels"
                            />
                            <ImageWithCaption
                                src="/projects/DynamicWall/Wall_VeneerTest2.jpg"
                                alt="Testing wood veneers with prototype LED panels"
                                caption="Testing wood veneers with prototype LED panels"
                            />
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
            <ThemeProvider tint="dark">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ImageGallery columns={2}>
                            <video controls css={[t.w_full]}>
                                <source src="/projects/DynamicWall/Artichoke.webm" type="video/webm" />
                                <source src="/projects/DynamicWall/Artichoke.mp4" type="video/mp4" />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                            <video controls css={[t.w_full]}>
                                <source src="/projects/DynamicWall/Goobeam.webm" type="video/webm" />
                                <source src="/projects/DynamicWall/Goobeam.mp4" type="video/mp4" />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                            <video controls css={[t.w_full]}>
                                <source src="/projects/DynamicWall/Pinkfalls.webm" type="video/webm" />
                                <source src="/projects/DynamicWall/Pinkfalls.mp4" type="video/mp4" />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                            <video controls css={[t.w_full]}>
                                <source src="/projects/DynamicWall/MatrixMan.webm" type="video/webm" />
                                <source src="/projects/DynamicWall/MatrixMan.mp4" type="video/mp4" />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                        </ImageGallery>
                        <ImageGallery columns={4}>
                            <ImageWithCaption src="/projects/DynamicWall/Thumb_Cascades_Coral.png" alt="" caption="" />
                            <ImageWithCaption src="/projects/DynamicWall/Thumb_Cascades_Teal.png" alt="" caption="" />
                            <ImageWithCaption src="/projects/DynamicWall/Thumb_Goobeam_Teal.png" alt="" caption="" />
                            <ImageWithCaption src="/projects/DynamicWall/Thumb_Goobeam_Merica2.png" alt="" caption="" />
                            <ImageWithCaption src="/projects/DynamicWall/Thumb_Matrix_Man.png" alt="" caption="" />
                            <ImageWithCaption src="/projects/DynamicWall/Thumb_Merica_Firework_Man.png" alt="" caption="" />
                            <ImageWithCaption src="/projects/DynamicWall/Thumb_Orange_Smoke.png" alt="" caption="" />
                            <ImageWithCaption src="/projects/DynamicWall/Thumb_Sea_Anemone.png" alt="" caption="" />
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
            <ThemeProvider tint="extra-light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ImageGallery>
                            <ImageWithCaption
                                src="/projects/DynamicWall/Wall_Controller.jpg"
                                alt="Dynamic Wall scene sditor software"
                                caption="Dynamic Wall scene editor software"
                            />
                        </ImageGallery>
                    </ProjectSection>
                </ProjectSectionContainer>
            </ThemeProvider>
        </ProjectSubPage>
    );
}

function Demo(props: { projectPath: string }) {
    return (
        <ProjectSubPage projectPath={props.projectPath}>
            <ThemeProvider tint="light">
                <ProjectSectionContainer>
                    <ProjectSection>
                        <ProjectSectionHeading label="Controller Walkthrough" />
                        <p></p>
                    </ProjectSection>
                    <ImageGallery>
                        <video controls css={[t.w_full]}>
                            <source
                                src="https://firebasestorage.googleapis.com/v0/b/colin-riley-website.appspot.com/o/DynamicWall_Demo.mp4?alt=media&token=eb8c1879-115f-44b0-8b95-5403851804a4"
                                type="video/mp4"
                            />
                            Sorry, your browser doesn't support embedded videos.
                        </video>
                    </ImageGallery>
                </ProjectSectionContainer>
            </ThemeProvider>
        </ProjectSubPage>
    );
}
