import { initializeApp } from 'firebase/app';
import { getAnalytics, isSupported } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: 'AIzaSyCdCSKfga1PnSjUWsDAqQCDhAhEXLIW-Bs',
    authDomain: 'colin-riley-website.firebaseapp.com',
    projectId: 'colin-riley-website',
    storageBucket: 'colin-riley-website.appspot.com',
    messagingSenderId: '210337111670',
    appId: '1:210337111670:web:5fe2981b7e8bc916460957',
    measurementId: 'G-BT2J9JJLVE',
};

export const FirebaseApp = initializeApp(firebaseConfig);
export function InitializeFirebase() {
    const asyncWrapper = async () => {
        const supported = await isSupported();
        if (supported) getAnalytics(FirebaseApp);
    };
    asyncWrapper();
}
