export enum palette {
    dark_1 = '#161718',
    dark_2 = '#222',
    dark_3 = '#333433',
    dark_4 = '#4c4d4b',
    dark_5 = '#5c5f61',

    primary_1 = '#4fc9ae',

    tint_1 = '#FFFEFC',
    tint_2 = '#F7F7F7',
    tint_3 = '#F4F0EC',
    tint_4 = '#d7dada',
    tint_5 = '#737677',

    error_1 = '#FF2828',
    error_2 = '#DD0000',

    success = '#08C269',
}
