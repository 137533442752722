/** @jsxImportSource @emotion/react */
import React from 'react';
import t from '../../../theme/styles';
import { ProjectArticle } from '../ProjectArticle';
import { ProjectBanner } from '../ProjectBanner';
import { ProjectAside } from '../ProjectAside';
import { ProjectPage } from '../ProjectPage';
import { ProjectSectionContainer, ProjectSection, ProjectSectionHeading } from '../ProjectSection';
import { ThemeProvider } from '../../../contexts/ThemeContext';
import { ImageWithCaption } from '../../../components/imageWithCaption';
import { ImageGallery } from '../../../components/imageGallery';
import { ProjectOverview } from '../ProjectOverview';

export function ProjectH2V() {
    return (
        <ProjectPage title="Halo 2 for Windows Vista">
            <ProjectBanner src="/projects/H2V/H2V_Header.jpg" alt="" />
            <ProjectArticle>
                <ProjectSectionContainer>
                    <ProjectOverview>
                        <ProjectSectionHeading label="Overview" />
                        <p>
                            My first real project! As part of the new Windows Vista OS and an effort to bring the Xbox LIVE services to the PC, Microsoft created an internal team
                            to port over Bungie's flagship title. Mostly a strict port there were PC focused features added on including a map editor, dedicated servers, and text
                            chat. The original game also predated the Xbox LIVE achievements so those were layered on top.
                        </p>
                    </ProjectOverview>

                    <ProjectAside title="Halo 2 for Windows Vista" company="Microsoft" date="2006" titleStyle={[t.type_5]}>
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Platform</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>PC</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Tools</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Bungie toolset, C++, Macromedia Director</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Skills</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>UX Design, UI Design, Integration, Prototyping</div>
                        </div>
                    </ProjectAside>
                </ProjectSectionContainer>

                <ThemeProvider tint="extra-light">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="My Role" />
                            <p>
                                As a UI Artist, I created or upgraded art assets and interactive screens including achievement icons, textures, UI elements, and a custom
                                installation experience. I also designed and prototyped the game browser screen which had to function with both a keyboard/mouse or gamepad.
                            </p>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
                <ThemeProvider tint="light">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="New Interfaces" />
                            <p>
                                As this was a port, I mostly just reauthored sprites to be higher resolution or adjusted screens for new aspect ratios. The new screens I designed
                                were mostly for dealing with the addition of a keyboard, so a key mapping interface and adding a chat module to the pre-game lobby.
                            </p>
                            <p>
                                The introduction of a multiplayer game browser was the most intensive new screen added to the game. A game browser is a fairly complicated screen on
                                it's own, adding the requirement that it also needed to be fully functional with a gamepad steered us toward a round of usability testing. I used
                                Macromedia Director to develop a simple clickthrough prototype for testing. As this was my experience with user research I learned a ton and many
                                tears were shed.
                            </p>
                            <ImageGallery columns={2}>
                                <ImageWithCaption src="/projects/H2V/H2V_browser.jpg" alt="Game Browser" caption="Game Browser" />
                                <ImageWithCaption src="/projects/H2V/H2V_lobby.jpg" alt="Lobby with chat" caption="Lobby with chat" />
                            </ImageGallery>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
                <ThemeProvider tint="extra-dark">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="Achievement Icons" />
                            <p>
                                I designed a full set of achievement icons to pair with the newly designed achievements. The style was based on the existing multiplayer icons
                                displayed when certain events are triggered, like a kill streak. A set of these got turned into magnets, which I didn't know about until I found
                                them for sale at Gamestop.
                            </p>
                            <ImageGallery>
                                <ImageWithCaption src="/projects/H2V/AchievementIconSheet.jpg" alt="Achievement Icons" caption="Achievement Icons" />
                            </ImageGallery>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
            </ProjectArticle>
        </ProjectPage>
    );
}
