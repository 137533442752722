/** @jsxImportSource @emotion/react */
import React from 'react';
import t from '../../../theme/styles';
import { ProjectArticle } from '../ProjectArticle';
import { ProjectBanner } from '../ProjectBanner';
import { ProjectAside } from '../ProjectAside';
import { ProjectPage } from '../ProjectPage';
import { ProjectSectionContainer, ProjectSection, ProjectSectionHeading } from '../ProjectSection';
import { ThemeProvider } from '../../../contexts/ThemeContext';
import { ImageWithCaption } from '../../../components/imageWithCaption';
import { ImageGallery } from '../../../components/imageGallery';
import { ProjectOverview } from '../ProjectOverview';

export function ProjectUnmaking() {
    return (
        <ProjectPage title="The Unmaking">
            <ProjectBanner src="/projects/Unmaking/Unmaking_Header.jpg" alt="" />
            <ProjectArticle>
                <ProjectSectionContainer>
                    <ProjectOverview>
                        <ProjectSectionHeading label="Overview" />
                        <p>
                            The Unmaking is an action game where you defend a castle from an onslaught of enemies. With touch-based controls you command various mounted weapons,
                            dragging your finger to aim your shots out from the castle wall. The game utilizes Amazon’s AppStream service to render PC quality graphics in the cloud
                            and stream to a tablet.
                        </p>
                    </ProjectOverview>

                    <ProjectAside title="The Unmaking" company="Amazon Game Studios" date="2014" titleStyle={[t.type_6]}>
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Platform</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Kindle Fire</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1, t.mb_4]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Tools</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>Unity, NGUI</div>
                        </div>
                        <div css={[t.flex, t.text_tint_1]}>
                            <div css={[t.font_heading, t.type_1, t.font_bold, t.mr_3]}>Skills</div>
                            <div css={[t.font_heading, t.font_normal, t.type_1]}>UX Design, UI Design, Integration, Prototyping</div>
                        </div>
                    </ProjectAside>
                </ProjectSectionContainer>

                <ThemeProvider tint="extra-light">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="My Role" />
                            <p>
                                My primary responsibilities were around the game’s UX design, control design, User Research planning, and UI integration. I also did early UI design
                                and collaborated with game designers on defining goals, adjusting mechanics and feedback. After the game's release, partnering with a game designer,
                                we were tasked with designing and planning for a follow up release. While we didn't end up pursuing the plan I think we identified significant
                                improvements.
                            </p>
                        </ProjectSection>
                        <ProjectSection>
                            <ProjectSectionHeading label="Challenges" />
                            <ul css={[t.list_disc, t.pl_4, t.font_body, t.type_1]}>
                                <li>
                                    Cloud rendered gameplay created limitation on controls and gameplay feedback. The technology performs best when camera remains fixed.
                                    Additionally, the control input is done locally and sent to the server so control feedback (like projected UI about where a shot might land)
                                    proved difficult.
                                </li>
                                <li>Balancing gameplay pace with a players ability to use/learn the controls competently.</li>
                                <li>An aggressive schedule didn’t allow for very much iteration or bake time.</li>
                            </ul>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
                <ThemeProvider tint="dark">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="UX Design" />
                            <p>Describe process of defining core loop, flow diagram, feedback sequence, etc.</p>
                            <ImageGallery>
                                <ImageWithCaption
                                    src="/projects/Unmaking/FeedbackSequence.png"
                                    alt="Feedback Sequence Diagram"
                                    caption="Feedback Sequence Diagram - Used for guiding the engineering around level transitions and tools"
                                />
                            </ImageGallery>
                        </ProjectSection>
                        <ProjectSection>
                            <ProjectSectionHeading label="HUD, Controls, and User Research" />
                            <p>
                                Defined core controls and tweaked parameters to optimize accuracy and speed. Collaborated with User Researchers to develop a testing plan that would
                                validate or invalidate our control choices. For a long time in development we had multiple controls schemes (mostly because it’s what some people
                                had grown accustomed to) but I moved us towards a single scheme that performed better in user research.
                            </p>
                            <p>
                                When I joined the project it had already been underway and had a variety of interactions (aiming & firing, weapon switching, ammo type switching,
                                and spell casting) that a player needed to manage in the heat of battle. I worked with designers to simplify those interactions to aiming & firing,
                                holding down to charge a shot (which alleviated the need for weapon/ammo switching) and spell casting. This also allowed for a simplified HUD and
                                more control space which I expanded to allow for better aiming accuracy and fewer misfires.
                            </p>
                            <ImageGallery columns={2}>
                                <ImageWithCaption
                                    src="/projects/Unmaking/Unmaking_earlyHUD.jpg"
                                    alt="Early HUD and controls for The Unmaking"
                                    caption="The HUD and controls when I joined the project"
                                />
                                <ImageWithCaption
                                    src="/projects/Unmaking/Unmaking_updatedHUD.png"
                                    alt="Final version of HUD for the Unmaking"
                                    caption="The shipped version of the HUD"
                                />
                            </ImageGallery>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
                <ThemeProvider tint="light">
                    <ProjectSectionContainer>
                        <ProjectSection>
                            <ProjectSectionHeading label="Future versions" />
                            <p>
                                After the release of the 1.0 product, Game Designer, Brett Johnson, and I were tasked with developing a design, plan, and schedule for the next
                                release. Our primary goals revolved around increasing PENS (Player Experience of Need Satisfaction) scores, specifically autonomy and competence,
                                while reusing and extending as much of the current assets as possible. Additionally, repetition was often one of our weakest points in usability
                                studies. One of the strengths of the product was the quality of both the characters and environment but the restrictions of both the streaming tech
                                and current design limited the view of the battlefield to one angle.
                            </p>
                            <p>
                                Our plan emphasized a simple and flexible mission system to both move you around the battefield and experience new gameplay dynamics. We drafted a
                                straight-forward narrative arc to tie missions together and introduced missions where you play as an archer to simplify the FTUE (First Time User
                                Experience) as well as increase relatedness.
                            </p>
                            <p>Below is a gallery of mission mockups</p>
                            <ImageGallery columns={2}>
                                <MissionImage
                                    src="/projects/Unmaking/Missions_pylons.jpg"
                                    alt="Intro mission"
                                    title="Intro Mission"
                                    caption="Narrative driven and threatless intro to teach controls"
                                />
                                <MissionImage
                                    src="/projects/Unmaking/Missions_DarkCaptain.jpg"
                                    alt="Unique character missions"
                                    title="Unique character missions"
                                    caption="Allows for dramatic sequences, focused gameplay, and asset reuse"
                                />
                                <MissionImage
                                    src="/projects/Unmaking/Missions_Defend.jpg"
                                    alt="Defense missions"
                                    title="Defense missions"
                                    caption="Opens up possibility for alternate positions and goals around the battlefield"
                                />
                                <MissionImage
                                    src="/projects/Unmaking/Missions_Destroy.jpg"
                                    alt="Key targets missions"
                                    title="Key targets missions"
                                    caption="Clearly display progress towards a goal"
                                />
                                <MissionImage
                                    src="/projects/Unmaking/Missions_KillTotal.jpg"
                                    alt="Kill total missions"
                                    title="Kill total missions"
                                    caption="Alternate gameplay possibilities open up when sheer quantity is the goal"
                                />
                                <MissionImage
                                    src="/projects/Unmaking/Missions_KillType.jpg"
                                    alt="Kill # of a specific type"
                                    title="Kill # of a specific type"
                                    caption="Focus on certain targets while surviving against all others"
                                />
                                <MissionImage
                                    src="/projects/Unmaking/v1-5_Elegy_GiantTitan.jpg"
                                    alt="Boss battles"
                                    title="Boss battles"
                                    caption="Scaled up assets for reuse and dramatic battles"
                                />
                            </ImageGallery>
                        </ProjectSection>
                    </ProjectSectionContainer>
                </ThemeProvider>
            </ProjectArticle>
        </ProjectPage>
    );
}

function MissionImage(props: { src: string; alt: string; title: string; caption: string }) {
    return (
        <div css={[t.flex, t.flex_col]}>
            <ImageWithCaption src={props.src} alt={props.alt} />
            <span css={[t.mt_2, t.type_1, t.font_heading, t.font_normal]}>{props.title}</span>
            <span css={[t.mt_1, t.type_0]}>{props.caption}</span>
        </div>
    );
}
