import React, { ReactNode, useContext } from 'react';

type Tint = 'white' | 'extra-light' | 'light' | 'primary' | 'dark' | 'extra-dark';

export interface ThemeController {
    tint?: Tint;
}

export const ThemeContext = React.createContext({} as ThemeController);
export const ThemeProvider = (props: { tint?: Tint; children: ReactNode }) => {
    return <ThemeContext.Provider value={{ tint: props.tint }}>{props.children}</ThemeContext.Provider>;
};

export function useThemeController() {
    return useContext(ThemeContext);
}
