import { css } from '@emotion/react';

export const textAlign = {
    text_left: css`
        text-align: left;
    `,
    text_center: css`
        text-align: center;
    `,
    text_right: css`
        text-align: right;
    `,
    text_justify: css`
        text-align: justify;
    `,
};
