import { css } from '@emotion/react';
import { palette } from './palette';

export const outline = {
    outline_none: css`
        outline: 2px solid transparent;
    `,
    outline_dark_1: css`
        outline: 2px solid ${palette.dark_1};
        outline-style: auto;
    `,
    outline_dark_2: css`
        outline: 2px solid ${palette.dark_2};
        outline-style: auto;
    `,
    outline_dark_3: css`
        outline: 2px solid ${palette.dark_3};
        outline-style: auto;
    `,
    outline_dark_4: css`
        outline: 2px solid ${palette.dark_4};
        outline-style: auto;
    `,
    outline_dark_5: css`
        outline: 2px solid ${palette.dark_5};
        outline-style: auto;
    `,
    outline_primary_1: css`
        outline: 2px solid ${palette.primary_1};
        outline-style: auto;
    `,
    outline_tint_1: css`
        outline: 2px solid ${palette.tint_1};
        outline-style: auto;
    `,
    outline_tint_2: css`
        outline: 2px solid ${palette.tint_2};
        outline-style: auto;
    `,
    outline_tint_3: css`
        outline: 2px solid ${palette.tint_3};
        outline-style: auto;
    `,
    outline_tint_4: css`
        outline: 2px solid ${palette.tint_4};
        outline-style: auto;
    `,
    outline_tint_5: css`
        outline: 2px solid ${palette.tint_5};
        outline-style: auto;
    `,
    outline_error_1: css`
        outline: 2px solid ${palette.error_1};
        outline-style: auto;
    `,
    outline_error_2: css`
        outline: 2px solid ${palette.error_2};
        outline-style: auto;
    `,
    outline_success: css`
        outline: 2px solid ${palette.success};
        outline-style: auto;
    `,
};
