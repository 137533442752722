/** @jsxImportSource @emotion/react */
import React from 'react';
import { Page } from '../../components/page';
import { TextRouteLink } from '../../components/TextRouteLink';
import t from '../../theme/styles';
import { ProjectCard } from '../projects/ProjectCard';
import { ProjectData } from '../../data/ProjectData';

export function HomepageProjects() {
    return (
        <div css={[t.bg_tint_1, t.pt_8, t.pb_9]}>
            <Page style={[t.flex, t.flex_col]}>
                <div css={[t.flex_auto, t.grid_gap_6, t.grid_1, t.sm([t.grid_2]), t.md([t.grid_gap_7]), t.lg([t.grid_4, t.grid_gap_y5]), t.xl([t.grid_gap_x7, t.grid_gap_y6])]}>
                    {Object.keys(ProjectData).map((projectKey: string, index: number) => {
                        if (index >= 8) return null;
                        const project = ProjectData[projectKey];
                        return <ProjectCard key={projectKey} index={index} label={project.Name} to={`/projects/${projectKey}`} src={project.Src} alt={project.Alt} />;
                    })}
                </div>
                <div css={[t.flex, t.flex_col, t.mt_6, t.justify_center, t.items_center, t.type_3, t.text_dark_5]}>
                    <TextRouteLink to="/projects/" label="All Projects" />
                </div>
            </Page>
        </div>
    );
}
