/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, Variants } from 'framer-motion';
import t from '../../theme/styles';

const hoverVariants: Variants = {
    hover: {},
    unhover: {},
    focus: {},
    blur: {},
    default: { scale: 0.9, opacity: 0 },
    reveal: (i) => ({ scale: 1, opacity: 1, transition: { ease: 'backOut', duration: 0.7, delay: i * 0.12 } }),
};

const cardVariants: Variants = {
    hover: { scale: 1.04, boxShadow: '2px 6px 12px rgba(0, 0, 0, 0.2)', transition: { ease: 'backOut', duration: 0.5 } },
    unhover: { scale: 1, boxShadow: '0px 0px 4px rgba(0, 0, 0, 0)', transition: { ease: 'easeInOut', duration: 0.3 } },
};

const overlayVariants: Variants = {
    hover: { x: '-55%', skewX: -25, opacity: 0.05, transition: { ease: 'backOut', duration: 0.5 } },
    unhover: { x: '-100%', skewX: -5, opacity: 0, transition: { ease: 'easeInOut', duration: 0.3 } },
};

const nameplateVariants: Variants = {
    hover: { x: 0, skewX: 5, opacity: 1, transition: { ease: 'circOut', duration: 0.38 } },
    unhover: { x: '-100%', skewX: 5, opacity: 0.5, transition: { ease: 'easeInOut', duration: 0.26 } },
};

const focusVariants: Variants = {
    focus: { scale: 1, opacity: 1, transition: { ease: 'backOut', duration: 0.5 } },
    blur: { scale: 0.8, opacity: 0, transition: { ease: 'easeInOut', duration: 0.3 } },
};

export function ProjectLargeCard(props: { index: number; label: string; to: string; src: string; alt: string }) {
    const [focus, SetFocus] = useState(false);
    const [hover, SetHover] = useState(false);

    return (
        <motion.div
            css={[t.flex, t.flex_col]}
            initial={['default', 'unhover', 'blur']}
            animate={['reveal', hover ? 'hover' : 'unhover', focus ? 'focus' : 'blur']}
            variants={hoverVariants}
            custom={props.index}
        >
            <Link
                to={props.to}
                css={[t.relative, t.outline_none, t.flex_auto, t.flex]}
                onFocus={() => SetFocus(true)}
                onBlur={() => SetFocus(false)}
                onMouseOver={() => SetHover(true)}
                onMouseLeave={() => SetHover(false)}
            >
                <motion.div css={[t.absolute, t.pos('-4px'), t.border_2, t.border_solid, t.border_primary_1]} variants={focusVariants} />
                <motion.div css={[t.relative, t.overflow_hidden]} variants={cardVariants}>
                    <span css={[t.flex, t.flex_auto]}>
                        <img src={props.src} alt={props.alt} width={400} height={284} css={[t.w_full, t.max_w_full, t.h_auto]} />
                    </span>
                    <motion.span variants={overlayVariants} css={[t.absolute, t.inset_0, t.w('120%'), t.bg_tint_1, t.left('-12%')]} />
                </motion.div>
            </Link>

            <h2 css={[t.relative, t.ml_3, t.mt('-20px')]}>
                <a
                    href={props.to}
                    css={[t.relative, t.font_heading, t.type_3, t.text_tint_1, t.font_normal, t.no_underline, t.px_3, t.py_2]}
                    tabIndex={-1}
                    onMouseOver={() => SetHover(true)}
                    onMouseLeave={() => SetHover(false)}
                >
                    <span css={[t.absolute, t.inset_0, t.overflow_hidden, t.bg_dark_4, t.skew_X(5)]}>
                        <motion.span variants={nameplateVariants} css={[t.absolute, t.inset_0, t.w('120%'), t.bg_primary_1, t.left('-12%')]} />
                    </span>

                    <span css={[t.relative]}>{props.label}</span>
                </a>
            </h2>
        </motion.div>
    );
}
