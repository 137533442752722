import { css } from '@emotion/react';

export const textTransform = {
    uppercase: css`
        text-transform: uppercase;
    `,
    lowercase: css`
        text-transform: lowercase;
    `,
    capitalize: css`
        text-transform: capitalize;
    `,
    normal_case: css`
        text-transform: none;
    `,
};
